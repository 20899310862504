import LoadingIndicator from 'components/LoadingIndicator';
import { Suspense, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { getTownsByLgaId } from 'hooks/Generic';
import Select from './select';

const TownSelect = ({ selectedLga, selected, handleChange, disabled = false }) => {
    var towns = useRecoilValue(getTownsByLgaId(selectedLga?.id));
    let selectedTowns = null;
    if (towns && selected) {
        selectedTowns = towns.find(item => item.id === selected.id)
    }

    if(towns!=null)
        towns=[...towns,{id:0,name:"Others"}];
    
    return (
        <Suspense fallback={<LoadingIndicator />}>
            {!disabled ?
                <Select id="town" className="form-select" placeholder={`Select Town/Village in ${selectedLga?.label} LGA`} selected={selectedTowns} handleChange={handleChange} options={towns || null} disabled={disabled} />
                : <input className="form-control" id="selectedtown" name='selectedtown' type="text" value={selectedTowns?.name} disabled={disabled} />}
        </Suspense>
    );
}

export default TownSelect;
