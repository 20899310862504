import { useLayoutEffect, useRef, useState } from "react";
import Sidebar from "./Sidebar";
import useClickOutside from "./Util/useClickOutside";
import { Link } from "react-router-dom";

const AppLayout = ({ children }) => {
    const navRef = useRef(null)
    const [clicked, setClicked] = useState(false)
    useLayoutEffect(() => {
        if (clicked) {
            document.body.classList.toggle('sb-sidenav-toggled');
        } else {
            document.body.classList.remove('sb-sidenav-toggled');
        }
    }, [clicked])

    const clickOutsidehandler = () => {
        if (clicked) {
            setClicked(false);
        }
    };
    useClickOutside(navRef, clickOutsidehandler);
    return (

        <>
            <div id="layoutSidenav_nav">

                <div ref={navRef}><Sidebar clicked={clicked} setClicked={setClicked} /></div>
               
                    <div className="nav">
                        <div id="layoutSidenav_content">
                            <main>
                            <section id="content">
                    <div id="survey" class="content-wrap">
                        <div class="container">
                            <div class="row align-items-top">

                                <div class="col-lg-2">
                                    <div class="sidebar-wrap">
                                        <div class="heading-block">
                                            <div class="spacer-80"></div>
                                            <h1>Take a <br></br>Quick Survey</h1>
                                            <div class="spacer-60"></div>
                                            <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                            <div className="sb-sidenav-menu">
                            <Link className="menu-item" to="/admin/dashboard" key="/admin/dashboard">
                                <div className="sb-nav-link-icon"></div>
                                Dashboard
                            </Link>
                            <hr></hr>
                            <Link className="menu-item" to="/admin/community" key="/admin/community">
                                <div className="sb-nav-link-icon"></div>
                                Community Projects
                            </Link>
                            <hr></hr>
                            <Link className="menu-item" to="/admin/ongoing" key="/admin/ongoing">
                                <div className="sb-nav-link-icon"></div>
                                Ongoing Projects
                            </Link>
                            <hr></hr>
                            <Link className="menu-item" to="/admin/youth" key="/admin/youth">
                                <div className="sb-nav-link-icon"></div>
                                Youth Job
                            </Link>
                            <hr></hr>
                            </div>
                            </nav>
                                            {/* <p>Start the survey by providing your personal details then proceed by answering the questions that follow.</p> */}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-10">
                                    <div class="content-block">
                                        <div class="spacer-70"></div>
                                        <div class="survey-wrap">
                                        {children}
                                        </div>
                                    </div>							
                                </div>						
                            </div>
                        </div>
                        
                    </div>
                    <div class="spacer-80"></div>
                </section>
                            
                            </main>
                        </div>
                    </div>
                
            </div>
        </> 
    );
}

export default AppLayout;
