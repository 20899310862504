import React, { useEffect, useMemo, useState } from "react";
// import useAdminActions from "hook/useAdminActions";
import { useQuery } from "react-query";
import Table from "components/shared/TableTwo";
import makeData from "components/Util/makeData";
import useAdminActions from "hooks/UseAdminActions";
import EnhancedTable from "components/shared/EnhancedTable";
import { toStartCase } from "utility/functions";
import { format } from "date-fns";
import SelectBox from "components/SelectBox";
import ManageForms from "pages/survery-forms/ManageForms";
import GlobalEnhancedTable from "components/shared/GlobalEnhancedTable";
import Select from "components/select";

const YouthJob = () => {
    const {getAllYouthJobs,getAllYouthJobsDownload } =useAdminActions(); 
    const [youthJobs, setYouthJob] = useState([]);
    const {youthJob,lgas } =ManageForms(); 
    const [data, setData] = React.useState(React.useMemo(() => makeData('call history', 30), []))
    const [page, setPage] = React.useState(1)
    const [search, setSearch] = React.useState('');
    const [download, setDownload] = React.useState(null);
    const [searchProject, setSearchProject] = React.useState('');
    const [skipPageReset, setSkipPageReset] = React.useState(false)
    const [donations, setDonations] = React.useState([])

    const allYouthJob = useQuery(['youthjobs', page,search,searchProject,download], () => getAllYouthJobs(page,search,searchProject,download), {
      onSuccess: (res) => {         
        setYouthJob(res.data.projects.data)
        // const permOpt = res.data.projects.data.map(item => {
        //   return { id: item.id, value: item.id, email: item.email, phone: item.phone_mobile, label: toFullName({ firstName: item.first_name, lastName: item.last_name }) }
        // })
        
        // setCommunityOpts(permOpt)
      },
      onError: err => {
        console.log(err)
        // notify('post creation failed!', { type: 'error' })
      },
      staleTime: Infinity,
      cacheTime: Infinity
    })
 
    
    const donationColumn = useMemo(
        () => [
    
          {
            Header: 'Full Name',
            id: 'creator',
            accessor: (row) => toStartCase(`${row?.personal_information?.first_name} ${row?.personal_information?.last_name}`),
          },
          {
            Header: 'Email',
            id: 'level',
            accessor: (row) => row?.personal_information?.email,
          },
          {
            Header: 'Phone Number',
            id: 'tagline',
            accessor: (row) => row?.personal_information?.phone,
          },
          {
            Header: 'Gender',
            id: 'gender',
            accessor: (row) => row?.personal_information?.gender,
          },

          {
            Header: 'Job',
            id: 'job',
            accessor: (row) => row?.job?.name,
          },         
          
          {
            Header: 'Date',
            id: 'created_at',
            accessor: (row) => format(new Date(row.created_at), 'dd/MM/yyyy'),
          },
          
        ],
        []
      )

 
    return (
        <>
            <div className="container-fluid px-4">
                <h1 className="mt-4">Youth Jobs</h1>
               
                
                
                <div className="card mb-4">
                    <div className="card-header">
                        <i className="fas fa-table me-1"></i>
                        Projects
                    </div>
                    <div className="card-body">
         <div className="row">
            <div className="col-3">
              <input type='text' placeholder='Global Search Record' className="form-control" 
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              />
            </div>
            <div className="col-3" style={{width:'20%'}}>
            <Select
                        placeholder="Select a Job"
                    id="job"
                    name="job"
                    className="wpforms-field-large wpforms-field-required choicesjs-select choices__input choices__input--hidden"
                    // selected={JSON.parse(selectedStatus)}
                    handleChange={(e) => {                      
                        setSearchProject(JSON.parse(e.target.value)?.value);
                    }}
                    options={youthJob}
                /> 
            
            </div>
           
            <div className="col-3">
            <button class= "btn btn-success" type="submit"
                         onClick={(e) =>{    
                           e.preventDefault()
                           getAllYouthJobsDownload(page,search,searchProject,1)
                           .then((response) => {
                            const url = window.URL.createObjectURL(new Blob([response]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `${Date.now()}.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                        })
                        }}
                        >
                        Download
                        </button> 
              
            </div>
            </div>
            
                    
<GlobalEnhancedTable
              columns={donationColumn}
              data={allYouthJob?.data?.data?.projects?.data || []}
            // data={[]}
              setData={setYouthJob}
              skipPageReset={skipPageReset}
              lastPage={allYouthJob?.data?.data?.projects?.last_page}
              currentPage={page}
              setCurrentPage={setPage}
              isFetching={allYouthJob?.isFetching || allYouthJob?.isLoading}
            />
            {/* <MembershipEnhancedTable
              columns={MembershipColumns}
              data={allDonors?.data?.data?.users?.data || []}
              setData={setDonors}
              skipPageReset={skipPageReset}
              lastPage={allDonors?.data?.data?.users?.last_page}
              currentPage={page}
              setCurrentPage={setPage}
              isFetching={allDonors?.isFetching || allDonors?.isLoading}
            /> */}
                    </div>
                </div>
                
            </div>
        </>
    );
}

export default YouthJob;
