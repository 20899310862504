import HeaderContent from "./HeaderContent";

const Header = () => {
    return (
        <div>
            <HeaderContent/>

            <section class="min-vh-90 min-vh-md-110 include-header bg-blue with-bg" style={{backgroundImage: `url('image/home-hero.jpg')`}}>
                <div class="slider-inner">				
                    <div class="slide-overlay"></div>
                    <div class="hero-search-menu">
                        <div class="container">
                            <div class="hero-content">
                                <div class="headline">
                                    <h2>Welcome to <br></br>Edo State <br></br>Social Impact Platform</h2>
                                </div>
                                <div class="headline-desc">
                                    <p>An Artificial Intellligence Microtargeted Development Data Project</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a href="#" data-scrollto="#content" data-offset="100" class="one-page-arrow dark">
                        <i class="icon-angle-down infinite animated fadeInDown"></i>
                    </a>

                </div>
            </section>
        </div>
    )

}
export default Header;