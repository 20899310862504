import LoadingIndicator from "components/LoadingIndicator";
import ManageForms from "./ManageForms";
import { Button } from "react-bootstrap";
import { Box } from "@mui/material";
import Select from "components/select";
import GenericCalls from "hooks/GenericCalls";
import { Suspense, useCallback, useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

const PersonalInformation=({onClickCallback,values, currentStep})=>{
    const [capVal, setCapVal]=useState(null);
    const{ formikPersonalInfo,activeStep, setActiveStep, steps, handleNext, handleBack } =ManageForms();
    const [page, SetPage]=useState(activeStep);
    // const
    const gender = [
        { id: 1, value: 'Male', label: 'Male' },
        { id: 2, value: 'Female', label: 'Female' }];

        const handlePhoneMobile=(e)=>{
            formikPersonalInfo.setFieldValue('phone_mobile',e);
          }
        // useEffect(() => {
        //     if(values!=null){
        //         formikPersonalInfo.setFieldValue('first_name', values.first_name)
        //         formikPersonalInfo.setFieldValue('last_name', values.last_name)
        //         formikPersonalInfo.setFieldValue('gender', values.gender)
        //         formikPersonalInfo.setFieldValue('phone_mobile', values.phone_mobile)
        //         formikPersonalInfo.setFieldValue('email', values.email)
        //     }
        // }, []);
        // useEffect(() => {
        //     SetPage(activeStep)
        // },[])
      const  handleKeyUp= (e) =>  {
        debugger
            
          }
    return (
       
            <form style={{display: currentStep==0?'': 'none'}}>
            <div>
                <div class="wpforms-page wpforms-page-1 " data-page="1">
                    <div id="wpforms-6-field_8-container" class="wpforms-field wpforms-field-pagebreak" data-field-id="8"></div>
                    <div id="wpforms-6-field_1-container" class="wpforms-field wpforms-field-html" data-field-id="1">
                        <div id="wpforms-6-field_1" aria-errormessage="wpforms-6-field_1-error">
                            <p>Begin by providing your personal details below.</p>
                        </div>
                    </div>
                    <div id="wpforms-6-field_3-container" class="wpforms-field wpforms-field-divider" data-field-id="3">
                        <h3 id="wpforms-6-field_3" name="wpforms[fields][3]" aria-errormessage="wpforms-6-field_3-error">Personal Information</h3>
                    </div>
                    <div id="wpforms-6-field_2-container" class="wpforms-field wpforms-field-name" data-field-id="2">
                        <fieldset><legend class="wpforms-field-label">Name <span class="wpforms-required-label" aria-hidden="true">*</span></legend>
                            <div class="wpforms-field-row wpforms-field-large">
                                <div class="wpforms-field-row-block wpforms-first wpforms-one-half">
                                    
                                    <input type="text" className="wpforms-field-name-first wpforms-field-required"
                                    name="first_name" placeholder="First Name" 
                                    aria-label="first_name" 
                                    value={formikPersonalInfo.values.first_name} 
                                    onChange={formikPersonalInfo.handleChange} 
                                    onBlur={formikPersonalInfo.handleBlur} 
                                    aria-describedby="basic-addon1"
                                    />
                                    {formikPersonalInfo.errors.first_name && formikPersonalInfo.touched.first_name && <small className="error">{formikPersonalInfo.errors.first_name} </small>}
                                    <label for="wpforms-6-field_2" class="wpforms-field-sublabel after wpforms-sublabel-hide">First</label>
                                </div>
                                <div class="wpforms-field-row-block wpforms-one-half">
                                    <input type="text" className="wpforms-field-name-first wpforms-field-required"
                                    name="last_name" placeholder="Last Name" 
                                    aria-label="last_name" 
                                    value={formikPersonalInfo.values.last_name} 
                                    onChange={formikPersonalInfo.handleChange} 
                                    onBlur={formikPersonalInfo.handleBlur} 
                                    aria-describedby="basic-addon1" />
                                    {formikPersonalInfo.errors.last_name && formikPersonalInfo.touched.last_name && <small className="error">{formikPersonalInfo.errors.last_name} </small>}
                                    <label for="wpforms-6-field_2-last" class="wpforms-field-sublabel after wpforms-sublabel-hide">Last</label>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div id="wpforms-6-field_4-container" class="wpforms-field wpforms-field-select wpforms-field-select-style-modern" data-field-id="4">
                        <label class="wpforms-field-label" for="wpforms-6-field_4">Gender <span class="wpforms-required-label" aria-hidden="true">*</span></label>
                        <div class="choices wpforms-field-row wpforms-field-large" data-type="select-one" tabindex="0" role="listbox" aria-haspopup="true" aria-expanded="false">
                        <Select
                        placeholder="Select your gender"
                        defaultVal={formikPersonalInfo.values.gender}
                    id="gender"
                    name="gender"
                    className="wpforms-field-large wpforms-field-required choicesjs-select choices__input choices__input--hidden"
                    // selected={JSON.parse(selectedStatus)}
                    handleChange={(e) => {
                        formikPersonalInfo.setFieldValue(
                        'gender',
                        JSON.parse(e.target.value)?.value
                    );
                    //   setSelectedStatus(e.target.value);
                    }}
                    options={gender}
                />                                                   
                        </div>
                        {formikPersonalInfo.errors.gender && formikPersonalInfo.touched.gender && <small className="error">{formikPersonalInfo.errors.gender} </small>}
                    
                    </div>

                    

                    <div id="wpforms-6-field_27-container" class="wpforms-field wpforms-field-text" data-field-id="27">
                        <label class="wpforms-field-label" for="wpforms-6-field_27">Phone Number <span class="wpforms-required-label" aria-hidden="true">*</span></label>
                        
                    
                        {/* <input type="text" maxLength="11" className="wpforms-field-name-first wpforms-field-required"
                            data-rule-inputmask-incomplete="1" data-inputmask-mask="+9[9][9] 999 999 9999[9]"
                            name="phone_mobile"
                            id="phone_mobile"
                            
                            placeholder="0_0--------" 
                            aria-label="phone_mobile" 
                            value={formikPersonalInfo.values.phone_mobile} 
                            onChange={formikPersonalInfo.handleChange} 
                            onBlur={formikPersonalInfo.handleBlur} 
                            aria-describedby="basic-addon1" /> */}
                            <div id="wpforms-6-field_4-container" class="wpforms-field wpforms-field-select wpforms-field-select-style-modern" data-field-id="4">
                    <PhoneInput
                country={'ng'}
                preferredCountries={['ng', 'gh', 'ke', 'us', 'uk']}
                excludeCountries={['ru', 'nk']}
                // value={formikPersonalInfo.values.phone_mobile}
                onChange={handlePhoneMobile}
                onBlur={formikPersonalInfo.handleBlur}
                inputProps={{
                  required: true,
                  type: 'tel',
                  autoComplete: 'tel-national',
                  id: 'phone_mobile',
                  name: 'phone_mobile',
                  inputMode: 'tel',
                  className: 'wpforms-field-name-first wpforms-field-required phone-input',
                  maxLength: 17
                }}
               
              />
                    </div>
                            {/* {phoneConfirm && <small className="error">{formikPersonalInfo.errors.phone_mobile} </small>} */}
                            {formikPersonalInfo.errors.phone_mobile && formikPersonalInfo.touched.phone_mobile && <small className="error">{formikPersonalInfo.errors.phone_mobile} </small>}
                        <div class="wpforms-field-limit-text" id="wpforms-field-limit-text-6-27" aria-live="polite">0 of 11 max characters.
                        </div>
                    </div>
                    <div id="wpforms-6-field_6-container" class="wpforms-field wpforms-field-email" data-field-id="6">
                        <label class="wpforms-field-label" for="wpforms-6-field_6">Email Address <span class="wpforms-required-label" aria-hidden="true">*</span></label>
                        <input type="email" className="wpforms-field-name-first wpforms-field-required"
                            name="email" placeholder="Email Address" 
                            aria-label="email" 
                            value={formikPersonalInfo.values.email} 
                            onChange={formikPersonalInfo.handleChange} 
                            onBlur={formikPersonalInfo.handleBlur} 
                            aria-describedby="basic-addon1" />
                        {formikPersonalInfo.errors.email && formikPersonalInfo.touched.email && <small className="error">{formikPersonalInfo.errors.email} </small>}
                                  
                        
                    </div> 
                    {/* <div class="test">
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        onChange={(val)=> setCapVal(val)}
                        />  </div> */}
                    {/* <div id="wpforms-6-field_7-container" class="wpforms-field wpforms-field-pagebreak" data-field-id="7">
                            <div class="wpforms-clear wpforms-pagebreak-left">
                            <button class="wpforms-page-button wpforms-page-next"
                            onClick={(e) =>{ 
                                formikPersonalInfo.handleSubmit();
                                onClickCallback(e)}}
                            >
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </button>
                            </div>
                        </div>            */}
                </div>

                <div class="row">
                            <button
                            class=" wpforms-page-button wpforms-page-prev"
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                            >
                            Back
                            </button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            
                            <button class= " wpforms-page-button wpforms-page-next" type="submit"
                            
                            // disabled={messageDonor?.isLoading}
                            onClick={(e) =>{ 
                                e.preventDefault() 
                                // verifyEmail(e)
                            
                            formikPersonalInfo.handleSubmit() 
                                                                            
                                if(formikPersonalInfo.isValid && formikPersonalInfo.dirty )
                                { 
                                    onClickCallback(formikPersonalInfo.values)
                                }
                            }}
                            >
                            {/* {messageDonorMutation.isLoading && <LoadingIndicator />} */}
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </button>    
                        </div>

                
                
            </div>
            </form>
        
    )
}
export default PersonalInformation;