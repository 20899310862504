import { Box } from "@mui/material";
import ManageForms from "./ManageForms";
import { Suspense, useEffect, useState } from "react";
import LoadingIndicator from "components/LoadingIndicator";
import TownSelect from "components/TownSelect";
import Select from "components/select";
import LgaSelect from "components/LgaSelect";

const CommunityAssociate=({onClickCallback, onClickBack, values, currentStep})=>{
   const{ formikCommunityAssociate,activeStep, setActiveStep, steps, handleNext, handleBack,locations,lgas,ongoingProject } =ManageForms();
   const [selectedLga, setSelectedLga] = useState()
   const [selectedTown, setSelectedTown] = useState()
   const [imagePreview, setImagePreview] = useState()
   const [checkTown, setCheckTown] = useState()
//    useEffect(() => {
//       if(values!=null){
//          formikCommunityAssociate.setFieldValue('town', values.town)
//          formikCommunityAssociate.setFieldValue('ongoing_project', values.ongoing_project)
//          formikCommunityAssociate.setFieldValue('ongoing_project_location', values.ongoing_project_location)
//          formikCommunityAssociate.setFieldValue('lga', values.lga)
//          formikCommunityAssociate.setFieldValue('longitude', values.longitude)
//          formikCommunityAssociate.setFieldValue('latitude', values.latitude)
//          formikCommunityAssociate.setFieldValue('image', values.image)
//          setImagePreview(values.image)
//       }
//   }, []);
   return (
      <form style={{display: currentStep==2?'': 'none'}}>
        <div class="wpforms-page wpforms-page-3  " data-page="3">
<div id="wpforms-6-field_20-container" class="wpforms-field wpforms-field-divider" data-field-id="20">
<h3 id="wpforms-6-field_20" name="wpforms[fields][20]" aria-errormessage="wpforms-6-field_20-error" aria-describedby="wpforms-6-field_20-description">Any Ongoing Community Association Development Project</h3>
<div id="wpforms-6-field_20-description" class="wpforms-field-description">Select a project area below and and upload a picture of the project
</div>
</div>
<div id="wpforms-6-field_21-container" class="wpforms-field wpforms-field-select wpforms-field-select-style-modern" data-field-id="21">
   <label class="wpforms-field-label" for="wpforms-6-field_21">Any Ongoing Project <span class="wpforms-required-label" aria-hidden="true">*</span></label>
   <div class="choices wpforms-field-row wpforms-field-large" data-type="select-one" tabindex="0" role="combobox" aria-autocomplete="list" aria-haspopup="true" aria-expanded="false">
      <div class="choices__inner">
      <Select
                    placeholder="Select any ongoing project"
                id="ongoing_project"
                className="wpforms-field-large wpforms-field-required choicesjs-select choices__input choices__input--hidden"
                // selected={JSON.parse(selectedStatus)}
                handleChange={(e) => {
                  
                  formikCommunityAssociate.setFieldValue(
                    'ongoing_project',
                    JSON.parse(e.target.value)?.value
                  );
                //   setSelectedStatus(e.target.value);
                }}
                options={ongoingProject}
                error={
                  formikCommunityAssociate.errors.ongoing_project &&
                  formikCommunityAssociate.touched.ongoing_project
                    ? formikCommunityAssociate.errors.ongoing_project
                    : null
                }
              />
        {formikCommunityAssociate.errors.ongoing_project && formikCommunityAssociate.touched.ongoing_project && <small className="error">{formikCommunityAssociate.errors.ongoing_project} </small>}
        
      </div>
   </div>
</div>
<div id="wpforms-6-field_27-container" class="wpforms-field wpforms-field-text" data-field-id="27">
   <label class="wpforms-field-label" for="wpforms-6-field_27">Project Name <span class="wpforms-required-label" aria-hidden="true">*</span></label>
   <input type="text"  className="wpforms-field-name-first wpforms-field-required"        
      name="project_name"
      id="project_name"         
      placeholder="Project Name"  
      value={formikCommunityAssociate.values.project_name} 
      onChange={formikCommunityAssociate.handleChange} 
      onBlur={formikCommunityAssociate.handleBlur} 
      aria-describedby="basic-addon1" />
      {formikCommunityAssociate.errors.project_name && formikCommunityAssociate.touched.project_name && <small className="error">{formikCommunityAssociate.errors.project_name} </small>}   
</div>

<div id="wpforms-6-field_27-container" class="wpforms-field wpforms-field-text" data-field-id="27">
   <label class="wpforms-field-label" for="wpforms-6-field_27">Project Description <span class="wpforms-required-label" aria-hidden="true">*</span></label>
   <input type="text"  className="wpforms-field-name-first wpforms-field-required"        
      name="project_description"
      id="project_description"         
      placeholder="Project Description"  
      value={formikCommunityAssociate.values.project_description} 
      onChange={formikCommunityAssociate.handleChange} 
      onBlur={formikCommunityAssociate.handleBlur} 
      aria-describedby="basic-addon1" />
      {formikCommunityAssociate.errors.project_description && formikCommunityAssociate.touched.project_description && <small className="error">{formikCommunityAssociate.errors.project_description} </small>}   
</div>

{/* <div id="wpforms-6-field_27-container" class="wpforms-field wpforms-field-text" data-field-id="27">
   <label class="wpforms-field-label" for="wpforms-6-field_27">Project Description <span class="wpforms-required-label" aria-hidden="true">*</span></label>
   <textarea  className="wpforms-field-name-first wpforms-field-required"        
      name="project_description"
      id="project_description"         
      placeholder="Project Description"  
      value={formikCommunityAssociate.values.project_description} 
      onChange={formikCommunityAssociate.handleChange} 
      onBlur={formikCommunityAssociate.handleBlur} 
      aria-describedby="basic-addon1" 
      >
         </textarea>
      {formikCommunityAssociate.errors.project_description && formikCommunityAssociate.touched.project_description && <small className="error">{formikCommunityAssociate.errors.project_description} </small>}   
</div> */}
<div id="wpforms-6-field_22-container" class="wpforms-field wpforms-field-file-upload" data-field-id="22">
   <label class="wpforms-field-label" for="wpforms-6-field_22">Image Upload <span class="wpforms-required-label" aria-hidden="true">*</span></label>
   <div class="wpforms-uploader dz-clickable" data-field-id="22" data-form-id="6" data-input-name="wpforms_6_22" data-extensions="jpg,jpeg,png" data-max-size="1048576" data-max-file-number="1" data-post-max-size="67108864" data-max-parallel-uploads="4" data-parallel-uploads="true" data-file-chunk-size="1048576">
<img src={imagePreview} style={{height: '10%', width:'10%'}}/>
   </div>
   <input type="file" class="dropzone-input" name="image" require 
   onChange={(e)=>{
      const fileReader=new FileReader();
      fileReader.onload=()=>{
         if(fileReader.readyState===2){
            formikCommunityAssociate.setFieldValue('image',e.target.files[0]);
            setImagePreview(fileReader.result)
         }
      }
      fileReader.readAsDataURL(e.target.files[0]);
   }}
   />
      
   <div id="wpforms-6-field_22-description" class="wpforms-field-description">Upload an image of the project selected above in JPG/PNG format, not more than 2MB</div>
   {formikCommunityAssociate.errors.image && formikCommunityAssociate.touched.image && <small className="error">{formikCommunityAssociate.errors.image} </small>}
    
</div>
<div id="wpforms-6-field_51-container" class="wpforms-field wpforms-field-select wpforms-conditional-trigger wpforms-field-select-style-modern" data-field-id="51">
   <label class="wpforms-field-label" for="wpforms-6-field_51">Ongoing Project Location <span class="wpforms-required-label" aria-hidden="true">*</span></label>
   <div class="choices wpforms-field-row wpforms-field-large" data-type="select-one" tabindex="0" role="listbox" aria-haspopup="true" aria-expanded="false">
      <div class="choices__inner">
      <Select
                    placeholder="Select ongoing project location"
                id="ongoing_project_location"
                className="wpforms-field-large wpforms-field-required choicesjs-select choices__input choices__input--hidden"
                // selected={JSON.parse(selectedStatus)}
                handleChange={(e) => {
                  
                  formikCommunityAssociate.setFieldValue(
                    'ongoing_project_location',
                    JSON.parse(e.target.value)?.id
                  );
                //   setSelectedStatus(e.target.value);
                }}
                options={locations}
                error={
                  formikCommunityAssociate.errors.ongoing_project_location &&
                  formikCommunityAssociate.touched.ongoing_project_location
                    ? formikCommunityAssociate.errors.ongoing_project_location
                    : null
                }
              />        
      </div>  
           
   </div>
   {formikCommunityAssociate.errors.ongoing_project_location && formikCommunityAssociate.touched.ongoing_project_location && <small className="error">{formikCommunityAssociate.errors.ongoing_project_location} </small>}
       
   <div id="wpforms-6-field_51-description" class="wpforms-field-description">Please choose a location for the projects you selected above. You can set a location by selecting the GPS Coordinates option.</div>
</div>
{
                     formikCommunityAssociate.values?.ongoing_project_location==="lga"?
                     (<div id="wpforms-6-field_30-container" class="wpforms-field wpforms-field-layout wpforms-conditional-hide" data-field-id="30">
                     <div class="wpforms-field-layout-columns wpforms-field-layout-preset-50-50">
                        <div class="wpforms-layout-column wpforms-layout-column-50">
                           <div id="wpforms-6-field_29-container" class="wpforms-field wpforms-field-select wpforms-conditional-field wpforms-conditional-trigger wpforms-field-select-style-modern wpforms-conditional-hide" data-field-id="29">
                              <label class="wpforms-field-label" for="wpforms-6-field_29">Local Government Area <span class="wpforms-required-label" aria-hidden="true">*</span></label>
                              <div class="choices wpforms-field-row wpforms-field-medium" data-type="select-one" tabindex="0" role="combobox" aria-autocomplete="list" aria-haspopup="true" aria-expanded="false">
                                 <div class="choices__inner">
                                 
                                    <Suspense fallback={<LoadingIndicator />} >
                                             <LgaSelect selected={selectedLga} lga={lgas} handleChange={(e) => {
                                             setSelectedLga(JSON.parse(e.target.value))
                                             formikCommunityAssociate.setFieldValue(
                                                'lga',
                                                JSON.parse(e.target.value)?.id
                                              );
                                             }}  />
                                          </Suspense>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="wpforms-layout-column wpforms-layout-column-50">
                           <div id="wpforms-6-field_31-container" class="wpforms-field wpforms-field-select wpforms-conditional-field wpforms-field-select-style-modern wpforms-conditional-hide" data-field-id="31" >
                              <label class="wpforms-field-label" for="wpforms-6-field_31">Town/Village</label>
                              <div class="choices wpforms-field-row wpforms-field-medium" data-type="select-one" tabindex="0" role="combobox" aria-autocomplete="list" aria-haspopup="true" aria-expanded="false">
                                 <div class="choices__inner">         
                                    <Suspense fallback={<LoadingIndicator />} >
                                       <TownSelect selectedLga={selectedLga} selected={selectedTown} handleChange={(e) => {
                                       setSelectedTown(JSON.parse(e.target.value))
                                       setCheckTown(JSON.parse(e.target.value)?.id)
                                       formikCommunityAssociate.setFieldValue(
                                          'town',
                                          JSON.parse(e.target.value)?.id
                                        );
                                       }}  />
                                       {checkTown==0?<input type="text" className="wpforms-field-name-first wpforms-field-required"
                                    name="town2" placeholder="Enter Town" 
                                    aria-label="contact_name" 
                                    value={formikCommunityAssociate.values.town2} 
                                    onChange={formikCommunityAssociate.handleChange} 
                                    onBlur={formikCommunityAssociate.handleBlur} 
                                    aria-describedby="basic-addon1"
                                    />:''
                                       }
                                    </Suspense>
                                 </div>
                                 
                              </div>

                              
                           </div>
                        </div>
                     </div>
                  </div>):""
                    }
<div class="row">
                        <button
                        class=" wpforms-page-button wpforms-page-prev"
                        color="inherit"
                        onClick={(e)=>{
                           e.preventDefault();
                           onClickBack()
                        }}
                        // onClick={onClickBack}
                        sx={{ mr: 1 }}
                        >
                        Back
                        </button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        
                        <button class= " wpforms-page-button wpforms-page-next" type="submit"
                         onClick={(e) =>{    
                           e.preventDefault()
                           formikCommunityAssociate.handleSubmit();
                            if(formikCommunityAssociate.isValid && formikCommunityAssociate.dirty)
                                onClickCallback(formikCommunityAssociate.values)
                        }}
                        >
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </button>    
                    </div>
         </div>
      </form>   
    )
}
export default CommunityAssociate;