import axios from 'axios'
import { notify } from 'components/Alerts';
import { useRecoilState } from 'recoil';
import { userToken } from './state/auth';
 


export const baseURL=process.env.REACT_APP_BASE_URL;
const useAxios = () => {
  
  // const router = useRouter() 
  const [auth, setAuth] = useRecoilState(userToken);

  const axiosInstance = axios.create({
    baseURL,
    headers: authHeader()
  });

  function authHeader() {
    const token = auth;
    const isLoggedIn = !!token;
    if (isLoggedIn) {
      return { Authorization: `Bearer ${token}` };
    } else {
      return {};
    }
  }

  axiosInstance.interceptors.request.use(async req => {
    req.headers['Accept'] = 'application/json';
    // req.headers['Content-Type'] = 'multipart/form-data';

    const token = auth;
    req.headers['Authorization'] = token ? `Bearer ${token}` : null;

    return req
  })

  axiosInstance.interceptors.response.use(
    (response) => {
      if ([401, 403].includes(response.status) && auth) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        setAuth(null);
      }
      return response.data
    },
    (error) => {
      if (error?.message?.includes('Network Error')) {
        notify('There seems to be something wrong with your internet connection, please check and try again.', { type: 'error', duration: 5000 })
        return
      }
      if (error?.response?.data) {
        return Promise.reject(error?.response?.data?.message || error?.response?.data)
      }
      return Promise.reject(error.message)
    });

  return axiosInstance
}

export default useAxios;
