import useAxios from "./useAxios";

const useAdminActions = () => {
  const axios = useAxios()
  
  const format={
    method: 'GET',
    responseType: 'blob', // important
};
  function dashboard(page = 1, search) {    
    return axios.get(`/admin/users?page=${page}&filter[search_first_last]=${search}`);    
  }

  function dashboardDownload(page = 1, search) {    
    return axios.get(`/admin/users?page=${page}&download=1&filter[search_first_last]=${search}`,format);    
  }

  function getAllCommunityProjects(page = 1, search,searchProject) {
      return axios.get(`/admin/communityProject?page=${page}&filter[project_id]=${searchProject}&filter[search_first_last]=${search}&include=city,lga,personal_information,project`);
    }

    function getAllCommunityProjectDownload(page = 1, search,searchProject) {
      return axios.get(`/admin/communityProject?page=${page}&download=1&filter[project_id]=${searchProject}&filter[search_first_last]=${search}&include=city,lga,personal_information,project`, format);
    }

  function getAllOngoingProjects(page = 1, search, lga, town,searchProject) {    
      return axios.get(`/admin/ongoingProject?page=${page}&filter[community_project_id]=${searchProject}&filter[local_government_id]=${lga}&filter[city_id]=${town}&filter[search_first_last]=${search}&include=city,lga,personal_information,project`);
   }

   function getAllOngoingProjectDownloads(page = 1, search, lga, town,searchProject) {    
    return axios.get(`/admin/ongoingProject?page=${page}&download=1&filter[community_project_id]=${searchProject}&filter[local_government_id]=${lga}&filter[city_id]=${town}&filter[search_first_last]=${search}&include=city,lga,personal_information,project`, format);
 }

  function getAllYouthJobs(page = 1, search, searchProject, download) {    
      return axios.get(`/admin/youthJob?page=${page}&filter[search_youth_job]=${searchProject}&filter[search_first_last]=${search}&include=personal_information,job`);
    
  }
  function getAllYouthJobsDownload(page = 1, search, searchProject, download) {
    return axios.get(`/admin/youthJob?page=${page}&filter[search_youth_job]=${searchProject}&download=1&filter[search_first_last]=${search}&download=${download}&include=personal_information,job`,format);
  }
  return {
    dashboard, dashboardDownload,
    getAllCommunityProjects, getAllCommunityProjectDownload,
    getAllOngoingProjects,getAllOngoingProjectDownloads,
    getAllYouthJobs, getAllYouthJobsDownload
  }
}
export default useAdminActions