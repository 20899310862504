import { selector, selectorFamily } from "recoil";
import GenericCalls from "./GenericCalls";
import axios from "axios";
import { baseURL } from 'hooks/useAxios';

const axiosInstance = axios.create({
    baseURL,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  });
// const {getAllLocation} = GenericCalls();
function getAllLocation() {
    return axiosInstance.get(`/lgas`)
  }

  function getTownByLga(id) {
    return axiosInstance.get(`/towns/${id}`)
  }

export const mapLgasToTown = selector({
    key: 'lga',
    get: async () => {
      const response = await getAllLocation()
      if (response.error) {
        throw response.error;
      }
      return response?.data?.lgas;
    },
  })

  export const getTownsByLgaId = selectorFamily({
    key: 'lgaTown',
    get: (id) => async () => {
      if (id) {
        const response = await getTownByLga(id)
        if (response.error) {
          throw response.error;
        }
        
        return response?.data?.data.towns;
      } else {
        return null
      }
    },
  })