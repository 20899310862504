import React, { useEffect, useMemo, useState } from "react";
// import useAdminActions from "hook/useAdminActions";
import { useQuery } from "react-query";
import Table from "components/shared/TableTwo";
import makeData from "components/Util/makeData";
import useAdminActions from "hooks/UseAdminActions";
import EnhancedTable from "components/shared/EnhancedTable";
import { toStartCase } from "utility/functions";
import { format } from "date-fns";
import SelectBox from "components/SelectBox";
import ManageForms from "pages/survery-forms/ManageForms";
import GlobalEnhancedTable from "components/shared/GlobalEnhancedTable";
import Select from "components/select";

const CommunityProject = () => {
    const {getAllCommunityProjects, getAllCommunityProjectDownload } =useAdminActions(); 
    const [search, setSearch] = React.useState('');
    const [searchProject, setSearchProject] = React.useState('');
    const [communityProj, setCommunityProj] = useState([]);
    const {ongoingProject,lgas } =ManageForms(); 
    const [data, setData] = React.useState(React.useMemo(() => makeData('call history', 30), []))
    const [page, setPage] = React.useState(1)
    const [skipPageReset, setSkipPageReset] = React.useState(false)
    const allCommunityProjects = useQuery(['projects', page,search,searchProject], () => getAllCommunityProjects(page,search,searchProject), {
      onSuccess: (res) => {         
          setCommunityProj(res.data.projects.data)
        
      },
      onError: err => {
        console.log(err)
        // notify('post creation failed!', { type: 'error' })
      },
      staleTime: Infinity,
      cacheTime: Infinity
    })
// debugger
    // const { data: history } = useQuery(['history'], dashboard, {
    //     select: (data) => data.data,
    //     onError: err => {
    //         console.log(err)
    //         // notify('post creation failed!', { type: 'error' })
    //     },
    //     staleTime: Infinity,
    //     cacheTime: Infinity
    // })
    
    
    const donationColumn = useMemo(
        () => [
    
          {
            Header: 'Full Name',
            id: 'creator',
            accessor: (row) => toStartCase(`${row?.personal_information?.first_name} ${row?.personal_information?.last_name}`),
          },
          {
            Header: 'Email',
            id: 'level',
            accessor: (row) => row?.personal_information?.email,
          },
          {
            Header: 'Phone Number',
            id: 'tagline',
            accessor: (row) => row?.personal_information?.phone,
          },
          {
            Header: 'Gender',
            id: 'gender',
            accessor: (row) => row?.personal_information?.gender,
          },

          {
            Header: 'Project',
            id: 'project',
            accessor: (row) => row?.project?.name,
          },
          {
            Header: 'Image',
            id: 'image',
            accessor: (row) =>row.image1!=null? <img src={row?.image1} style={{height: '10%', width:'30%'}}/>: '',
          },
          {
            Header: 'Name',
            id: 'projectName',
            accessor: (row) => row?.project_name,
          },
          {
            Header: 'Description',
            id: 'projectDesc',
            accessor: (row) => row?.project_description,
          },
          
          {
            Header: 'Date',
            id: 'created_at',
            accessor: (row) => format(new Date(row.created_at), 'dd/MM/yyyy'),
          },
          
        ],
        []
      )

 
    return (
        <>
            <div className="container-fluid px-4">
                <h1 className="mt-4">Community Project</h1>
               
                
                
                <div className="card mb-4">
                    <div className="card-header">
                        <i className="fas fa-table me-1"></i>
                        Projects
                    </div>
                    <div className="card-body">
         <div className="row">
            <div className="col-3">
              <input type='text' placeholder='Global Search Record' className="form-control" 
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              />
            </div>
            <div className="col-3 choices wpforms-field-row wpforms-field-large">           
               <Select
                        placeholder="Select Project"
                    id="project"
                    name="project"
                    className="wpforms-field-large wpforms-field-required choicesjs-select choices__input choices__input--hidden"
                    // selected={JSON.parse(selectedStatus)}
                    handleChange={(e) => {                        
                        setSearchProject(JSON.parse(e.target.value)?.value);
                    }}
                    options={ongoingProject}
                /> 
                     
           
            </div>

            <div className="col-3">
            <button class= "btn btn-success" type="submit"
                         onClick={(e) =>{    
                           e.preventDefault()
                           getAllCommunityProjectDownload(page,search,searchProject)
                           .then((response) => {
                            const url = window.URL.createObjectURL(new Blob([response]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `communityProject.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                        })
                        }}
                        >
                        Download
                        </button> 
              
            </div>
           
            </div>
            
                    
<GlobalEnhancedTable
              columns={donationColumn}
              data={allCommunityProjects?.data?.data?.projects?.data || []}
            // data={[]}
              setData={setCommunityProj}
              skipPageReset={skipPageReset}
              lastPage={allCommunityProjects?.data?.data?.projects?.last_page}
              currentPage={page}
              setCurrentPage={setPage}
              isFetching={allCommunityProjects?.isFetching || allCommunityProjects?.isLoading}
            />
            {/* <MembershipEnhancedTable
              columns={MembershipColumns}
              data={allDonors?.data?.data?.users?.data || []}
              setData={setDonors}
              skipPageReset={skipPageReset}
              lastPage={allDonors?.data?.data?.users?.last_page}
              currentPage={page}
              setCurrentPage={setPage}
              isFetching={allDonors?.isFetching || allDonors?.isLoading}
            /> */}
                    </div>
                </div>
                
            </div>
        </>
    );
}

export default CommunityProject;
