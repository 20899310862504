import { Box } from "@mui/material";
import ManageForms from "./ManageForms";
import SelectBox from "components/SelectBox";
import { Suspense, useEffect, useState } from "react";
import LoadingIndicator from "components/LoadingIndicator";
import LgaSelect from "components/LgaSelect";
import TownSelect from "components/TownSelect";
import Select from "components/select";
import CheckBoxGroup from "components/CheckBoxGroup";
import SelectM from "components/SelectM";
import PhoneInput from "react-phone-input-2";

const CommunityProject=({onClickCallback, onClickBack, values, currentStep})=>{
   const{ formikCommunityProject,activeStep, setActiveStep, steps, handleNext, handleBack,handleSelectRegularchange, projects,lgas,locations } =ManageForms();
   // debugger
   // console.log(lgas)
//    useEffect(() => {
//       if(values!=null){
//          formikCommunityProject.setFieldValue('town', values.town)
//          formikCommunityProject.setFieldValue('select_location', values.select_location)
//          formikCommunityProject.setFieldValue('community_project', values.community_project)
//          formikCommunityProject.setFieldValue('lga', values.lga)
//          formikCommunityProject.setFieldValue('longitude', values.longitude)
//          formikCommunityProject.setFieldValue('latitude', values.latitude)
//          formikCommunityProject.setFieldValue('landmark', values.landmark)
//       }
//   }, []);
   const [selectedLga, setSelectedLga] = useState()
   const [selectedTown, setSelectedTown] = useState()
   const [checkTown, setCheckTown] = useState()
   const handlePhoneMobile=(e)=>{
      formikCommunityProject.setFieldValue('phone_mobile',e);
    }
   return (
      <form style={{display: currentStep==1?'': 'none'}}>
        <div class="wpforms-page wpforms-page-2  " data-page="2" >
                    <div id="wpforms-6-field_10-container" class="wpforms-field wpforms-field-divider" data-field-id="10">
                        <h3 id="wpforms-6-field_10" name="wpforms[fields][10]" aria-errormessage="wpforms-6-field_10-error" aria-describedby="wpforms-6-field_10-description">Community Project Priority</h3>
                        <div id="wpforms-6-field_10-description" class="wpforms-field-description">Select up to 4 options as your community project priority from the community project options listed below.</div>
                    </div>
                    <div id="wpforms-6-field_2-container" class="wpforms-field wpforms-field-name" data-field-id="2">
                        <fieldset><legend class="wpforms-field-label">Name of Community Contact Person <span class="wpforms-required-label" aria-hidden="true">*</span></legend>
                            <div class="wpforms-field-row wpforms-field-large">
                                <div class="wpforms-field-row-block wpforms-first wpforms-one-half">
                                    
                                    <input type="text" className="wpforms-field-name-first wpforms-field-required"
                                    name="contact_name" placeholder="Contact Name" 
                                    aria-label="contact_name" 
                                    value={formikCommunityProject.values.contact_name} 
                                    onChange={formikCommunityProject.handleChange} 
                                    onBlur={formikCommunityProject.handleBlur} 
                                    aria-describedby="basic-addon1"
                                    />
                                    {formikCommunityProject.errors.contact_name && formikCommunityProject.touched.contact_name && <small className="error">{formikCommunityProject.errors.contact_name} </small>}
                                    <label for="wpforms-6-field_2" class="wpforms-field-sublabel after wpforms-sublabel-hide">Contact Name12</label>
                                </div>                                
                            </div>
                        </fieldset>
                    </div>
                    <div id="wpforms-6-field_27-container" class="wpforms-field wpforms-field-text" data-field-id="27">
                        <label class="wpforms-field-label" for="wpforms-6-field_27">Contact Phone Number <span class="wpforms-required-label" aria-hidden="true">*</span></label>
                        {/* <input type="text" maxLength="11" className="wpforms-field-name-first wpforms-field-required"
                            data-rule-inputmask-incomplete="1" data-inputmask-mask="+9[9][9] 999 999 9999[9]"
                            name="phone_mobile"
                            id="phone_mobile"
                            
                            placeholder="0_0--------" 
                            aria-label="phone_mobile" 
                            value={formikCommunityProject.values.phone_mobile} 
                            onChange={formikCommunityProject.handleChange} 
                            onBlur={formikCommunityProject.handleBlur} 
                            aria-describedby="basic-addon1" /> */}
                            <div id="wpforms-6-field_4-container" class="wpforms-field wpforms-field-select wpforms-field-select-style-modern" data-field-id="4">
                    <PhoneInput
                country={'ng'}
                preferredCountries={['ng', 'gh', 'ke', 'us', 'uk']}
                excludeCountries={['ru', 'nk']}
                // value={formikPersonalInfo.values.phone_mobile}
                onChange={handlePhoneMobile}
                onBlur={formikCommunityProject.handleBlur}
                inputProps={{
                  required: true,
                  type: 'tel',
                  autoComplete: 'tel-national',
                  id: 'phone_mobile',
                  name: 'phone_mobile',
                  inputMode: 'tel',
                  className: 'wpforms-field-name-first wpforms-field-required phone-input',
                  maxLength: 17
                }}
               
              />
                    </div>
                            {/* {phoneConfirm && <small className="error">{formikPersonalInfo.errors.phone_mobile} </small>} */}
                            {formikCommunityProject.errors.phone_mobile && formikCommunityProject.touched.phone_mobile && <small className="error">{formikCommunityProject.errors.phone_mobile} </small>}
                        <div class="wpforms-field-limit-text" id="wpforms-field-limit-text-6-27" aria-live="polite">0 of 11 max characters.
                        </div>
                    </div>
                    <div id="wpforms-6-field_11-container" class="wpforms-field wpforms-field-checkbox" data-field-id="11">
                        <fieldset><legend class="wpforms-field-label">Community Projects</legend>                            
                              <CheckBoxGroup
                              formik={formikCommunityProject} 
                              label="Checkbox Topics" 
                              name="community_project"
                              options={projects}
                              errors={formikCommunityProject.errors.community_project &&
                                 formikCommunityProject.touched.community_project
                                   ? formikCommunityProject.errors.community_project
                                   : null}
                              // error={formikCommunityProject.values.community_project.length ==0 ?'project(s) is required': formikCommunityProject.values.community_project.length!=4?"You must select 4 projects":""}
                              />  
                           
                        </fieldset>
                    </div>
                    <div id="wpforms-6-field_13-container" class="wpforms-field wpforms-field-select wpforms-conditional-trigger wpforms-field-select-style-modern" data-field-id="13">
                        <label class="wpforms-field-label" >Location for the Project <span class="wpforms-required-label" aria-hidden="true">*</span></label>
                        <div class="choices wpforms-field-row wpforms-field-large" data-type="select-one" tabindex="0" role="listbox" aria-haspopup="true" aria-expanded="false">
                            <div class="choices__inner">
                              <SelectM 
                              placeholder={"Select a location"}
                              formik={formikCommunityProject}
                              name="select_location"
                              label="Select Location"
                              options={locations}
                              />
                            {/* <SelectBox
                    selectOption={"Select a location"}
                id="select_location"
                value={formikCommunityProject.values.select_location}
                className="wpforms-field-large wpforms-field-required choicesjs-select choices__input choices__input--hidden"
                // selected={JSON.parse(selectedStatus)}
                handleChange={(e) => {
                  debugger
                  formikCommunityProject.setFieldValue(
                    'select_location',
                    JSON.parse(e.target.value)?.value
                  );
                //   setSelectedStatus(e.target.value);
                }}
               itemList={locations}
                error={
                  formikCommunityProject.errors.select_location &&
                  formikCommunityProject.touched.select_location
                    ? formikCommunityProject.errors.select_location
                    : null
                }
              /> */}
                                                    
                            </div>
                        </div>
                        <div id="wpforms-6-field_13-description" class="wpforms-field-description">Please choose a location for the projects you selected above. You can set a location by selecting the GPS Coordinates option.</div>
                    </div>
                    {
                     formikCommunityProject.values?.select_location==="lga"?
                     (<div id="wpforms-6-field_30-container" class="wpforms-field wpforms-field-layout wpforms-conditional-hide" data-field-id="30">
                     <div class="wpforms-field-layout-columns wpforms-field-layout-preset-50-50">
                        <div class="wpforms-layout-column wpforms-layout-column-50">
                           <div id="wpforms-6-field_29-container" class="wpforms-field wpforms-field-select wpforms-conditional-field wpforms-conditional-trigger wpforms-field-select-style-modern wpforms-conditional-hide" data-field-id="29">
                              <label class="wpforms-field-label" for="wpforms-6-field_29">Local Government Area <span class="wpforms-required-label" aria-hidden="true">*</span></label>
                              <div class="choices wpforms-field-row wpforms-field-medium" data-type="select-one" tabindex="0" role="combobox" aria-autocomplete="list" aria-haspopup="true" aria-expanded="false">
                                 <div class="choices__inner">
                                 
                                    <Suspense fallback={<LoadingIndicator />} >
                                             <LgaSelect selected={selectedLga} lga={lgas} handleChange={(e) => {
                                             setSelectedLga(JSON.parse(e.target.value))
                                             formikCommunityProject.setFieldValue(
                                                'lga',
                                                JSON.parse(e.target.value)?.id
                                              );
                                             }}  />
                                          </Suspense>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="wpforms-layout-column wpforms-layout-column-50">
                           <div id="wpforms-6-field_31-container" class="wpforms-field wpforms-field-select wpforms-conditional-field wpforms-field-select-style-modern wpforms-conditional-hide" data-field-id="31" >
                              <label class="wpforms-field-label" for="wpforms-6-field_31">Town/Village</label>
                              <div class="choices wpforms-field-row wpforms-field-medium" data-type="select-one" tabindex="0" role="combobox" aria-autocomplete="list" aria-haspopup="true" aria-expanded="false">
                                 <div class="choices__inner">         
                                    <Suspense fallback={<LoadingIndicator />} >
                                       <TownSelect selectedLga={selectedLga} selected={selectedTown} handleChange={(e) => {
                                       setSelectedTown(JSON.parse(e.target.value))
                                       setCheckTown(JSON.parse(e.target.value)?.id)
                                       formikCommunityProject.setFieldValue(
                                          'town',
                                          JSON.parse(e.target.value)?.id
                                        );
                                       }}  />
                                    </Suspense>
                                    {checkTown==0?<input type="text" className="wpforms-field-name-first wpforms-field-required"
                                    name="town2" placeholder="Enter Town" 
                                    aria-label="contact_name" 
                                    value={formikCommunityProject.values.town2} 
                                    onChange={formikCommunityProject.handleChange} 
                                    onBlur={formikCommunityProject.handleBlur} 
                                    aria-describedby="basic-addon1"
                                    />:''
                                       }
                                 </div>
                                 
                              </div>

                              
                           </div>
                        </div>
                     </div>
                  </div>):""
                    }
                  






<div id="wpforms-6-field_50-container" class="wpforms-field wpforms-field-text" data-field-id="50">
<label class="wpforms-field-label" for="wpforms-6-field_50">Landmark</label>
<input type="text"
 class="wpforms-field-large"
  placeholder="e.g. Round About or Market Square"  
  name="landmark"
  value={formikCommunityProject.values.landmark} 
   onChange={formikCommunityProject.handleChange} 
   onBlur={formikCommunityProject.handleBlur} 
  />
   {formikCommunityProject.errors.landmark && formikCommunityProject.touched.landmark && <small className="error">{formikCommunityProject.errors.landmark} </small>}
                               
<div id="wpforms-6-field_50-description" class="wpforms-field-description">Describe or name a landmark close to the village or location selected
</div>
</div>

{
   formikCommunityProject.values?.select_location==="gps"?
   (
      <>
      <div id="wpforms-6-field_16-container" class="wpforms-field wpforms-field-html wpforms-conditional-field wpforms-conditional-hide" data-field-id="16">
         <div id="wpforms-6-field_16" aria-errormessage="wpforms-6-field_16-error">
            <p>Open up <a href="https://www.google.com/maps" targer="_blank">Google Maps</a> find the location of your choice and copy the coordinates (Latitude and Longitude below).
            </p>
         </div>
      </div>
      <div id="wpforms-6-field_18-container" class="wpforms-field wpforms-field-layout wpforms-conditional-hide" data-field-id="18">

      <div class="wpforms-field-layout-columns wpforms-field-layout-preset-50-50">
         <div class="wpforms-layout-column wpforms-layout-column-50">
         <div id="wpforms-6-field_15-container" class="wpforms-field wpforms-field-text wpforms-conditional-field wpforms-conditional-hide" data-field-id="15">
         <label class="wpforms-field-label" for="wpforms-6-field_15">Latitude <span class="wpforms-required-label" aria-hidden="true">*</span></label>
         <input type="text"
            class="wpforms-field-large wpforms-field-required"
            name="latitude"
            placeholder="Latitude"
            value={formikCommunityProject.values.latitude} 
            onChange={formikCommunityProject.handleChange} 
            onBlur={formikCommunityProject.handleBlur} 
           />
         </div>
         </div>
         <div class="wpforms-layout-column wpforms-layout-column-50">
            <div id="wpforms-6-field_17-container" class="wpforms-field wpforms-field-text wpforms-conditional-field wpforms-conditional-hide" data-field-id="17">
               <label class="wpforms-field-label" for="wpforms-6-field_17">Longitude <span class="wpforms-required-label" aria-hidden="true">*</span></label>
               <input type="text" class="wpforms-field-large wpforms-field-required" 
                placeholder="Longitude"
                name="longitude"
                value={formikCommunityProject.values.longitude} 
               onChange={formikCommunityProject.handleChange} 
               onBlur={formikCommunityProject.handleBlur} 
                />
            </div>
         </div>
      </div>
      </div>
      </>
   ):""
}



<div class="row">
                        <button
                        class=" wpforms-page-button wpforms-page-prev"
                        color="inherit"
                        // disabled={activeStep === 0}
                        onClick={(e)=>{
                           e.preventDefault();
                           onClickBack()
                        }}
                        // onClick={onClickBack}
                        sx={{ mr: 1 }}
                        >
                        Back
                        </button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        
                        <button class= " wpforms-page-button wpforms-page-next" type="submit"
                         onClick={(e) =>{ 
                           e.preventDefault()    
                           formikCommunityProject.handleSubmit();                                      
                            if(formikCommunityProject.isValid&& formikCommunityProject.dirty)
                            {
                            
                              onClickCallback(formikCommunityProject.values)
                            }
                                
                        }}
                        >
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </button>    
                    </div>

         </div>
      </form>
    )
}
export default CommunityProject;