import { notify } from "components/Alerts"
import { useFormik } from "formik"
import GenericCalls from "hooks/GenericCalls"
import { userSelector } from "hooks/state/user"
import Survey2Form from "pages/survey2-form"
import { useRef, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useRecoilValue } from "recoil"
import * as Yup from 'yup'
import PersonalInformation from "./personal-information"



  const validationCommunityProjectSchema = Yup.object({
    select_location: Yup.string().required('Field cannot be empty'),
    landmark: Yup.string().required('Field cannot be empty'),
    community_project: Yup.array()
    .required('Field cannot be empty')
    .test('valid-domain', 'You must select 4 community projects', (value) => {
      // debugger
          return value.length==4;
        })
    ,
   contact_name: Yup.string().required('Field cannot be empty'),
   phone_mobile: Yup.string()
        .required('Field cannot be empty')
        .length(13, 'Phone number must be 13 digits only')
        .matches(/^\d+$/, 'Phone number must be digit 0-9 only')
  })

  const validationCommunityAssociationSchema = Yup.object({
    ongoing_project: Yup.string().required('Field cannot be empty'),
    ongoing_project_location: Yup.string().required('Field cannot be empty'),
    project_name: Yup.string().required('Field cannot be empty'),
    project_description: Yup.string().max(300, 'Project description must not be more than 300 characters').required('Field cannot be empty'),
    // image: Yup.string().required('Image cannot be empty'),
   
  })
  const validationJobSchema = Yup.object({
    jobs: Yup.array().required('Field cannot be empty')
    .test('valid-domain', 'You must select 4 jobs', (value) => {
      // debugger
          return value.length==4;
        }),   
  })
  

const ManageForms=()=>{
    // const steps = ['Personal Information', 'Community Project Priority', 'Community Association Development',  'Youth Jobs Creation'];
    const steps = ['', '', '',  ''];
    // const [PersonalInfoValues, setPersonalInfoValues]=useState();
  const {phase} = useState();
  const {     
    first_name,last_name, email, phone_mobile, gender,town, select_location, community_project, lga, longitude, latitude, landmark        
  }= useState();

  const valuesG ={     
    first_name,last_name, email, phone_mobile, gender,town, select_location, community_project, lga, longitude, latitude, landmark        
  }
  const [personal, setPersonal] = useState();
  const [projectData, setProjectData] = useState();
  const [communityData, setCommunityData] = useState();
  const [youthData, setYouthData] = useState();
  
  
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(0);
    const {personalInfo, getAllProperty,getAllLocation,getOngoingProject, getYouthJob,verifyPhone,verifyEmail} = GenericCalls();
    
    const validationPersonalFormSchema = Yup.object().shape({
      anonymous: Yup.boolean(),
      first_name: Yup.string()
        .matches(
          /^[a-zA-Z0-9\s .!?,"-]+$/,
          'field accepts only string, numbers and hyphens'
        )
        .required('Field cannot be empty'),
      last_name: Yup.string()
        .matches(
          /^[a-zA-Z0-9\s .!?,"-]+$/,
          'field accepts only string, numbers and hyphens'
        )
        .required('Field cannot be empty'),
      email: Yup.string()
        .email('must be a valid email')
        .required('Must not be empty')
        // .test('valid-domain', 'Invalid email domain', (value) => {
        //   return value.endsWith('.com');
        // })
        .test('checkDuplUsername', 'Email already exists', function (value) {
          return new Promise((resolve, reject) => {
            verifyEmail({email:value})
              .then((resp) => {                
                  // exists
                  resolve(true)
              }).catch(() => {                
                  // note exists
                  resolve(false)
              })
          })
      }),
      phone_mobile: Yup.string()
        .required('Field cannot be empty')
        .length(13, 'Phone number must be 13 digits only')
        .matches(/^\d+$/, 'Phone number must be digit 0-9 only')
        .test('checkDuplUsername', 'phone number already exist', function (value) {
          return new Promise((resolve, reject) => {
            verifyPhone({phone:value})
              .then((resp) => {
                  // exists
                  resolve(true)
              }).catch(() => {
                  // note exists
                  resolve(false)
              })
          })
      }), 
      gender:Yup.string()
       .required('Select Gender')   
      // gender: Yup.string().when("anonymous", {
      //   is: false,
      //   then: Yup
      //     .string()
      //     .required("Field cannot be empty")
      // })
    })
    // const userDetails = useRecoilValue(userSelector);
    const personalInformationMutation = useMutation(personalInfo, {
        onSuccess: (res) => {
          window.open(res.data.authorization_url, '_self');
        },
        onError: err => {
        //   notify(err, { type: 'error' })
        
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        handleNext()
        }
      })
      const handleNext = (details) => {
        // let newSkipped = skipped;
        // if (isStepSkipped(activeStep)) {
        //   newSkipped = new Set(newSkipped.values());
        //   newSkipped.delete(activeStep);
        // }   
        
             if(details.type=="personal")
             {
              var values=details
              // valuesG.email=details?.email;
              // valuesG.first_name=details?.first_name;
              // valuesG.last_name=details?.last_name;
              // valuesG.gender=details?.gender;
              // valuesG.phone_mobile=details?.phone_mobile;
               const userDetails = {
                email: values.email,
                first_name: values.first_name,
                last_name: values.last_name,
                phone_mobile: values.phone_mobile,
                gender:values.gender
              }
              setPersonal((v)=>userDetails)
              // setDetails((v)=>valuesG)
             }
             else if(details.type=="project")
             {
             
              const projectDetails=
              {
                community_project:details?.community_project,
                landmark:details?.landmark,
                latitude:details?.latitude,
                longitude:details?.longitude,
                select_location:details?.select_location,
                town:details?.town,
                town2:details?.town2,
                lga:details?.lga,
                contact_name: details?.contact_name,
                contact_mobile: details?.phone_mobile
              }
                setProjectData((v)=>projectDetails);
                // setDetails((v)=>valuesG)
             }

             else if(details.type=="community")
             {
              console.log(personal);
              console.log(projectData);
              const community={ 
                ongoing_project_location: details?.ongoing_project_location,
                    lga:  details?.lga,
                    town:  details?.town,
                    town2:  details?.town2,
                    ongoing_project: details?.ongoing_project,
                    longitude:details?.longitude,
                    latitude: details?.latitude,
                    project_name: details.project_name,
                    project_description: details.project_description,
                    image: details?.image        
                }
                setCommunityData((v)=>community);
             }

             else if(details.type=="youth")
             {
              console.log(personal);
              console.log(projectData);
              console.log(communityData);
              const youth={ 
                    jobs: details?.jobs,
                    other_job:  details?.other_job,
                    suggestion:  details?.suggestion,
                }
                setYouthData((v)=>youth);
                var data={personal:personal,project:projectData,community:communityData,youth:youth};
                personalInformationMutation.mutate(data)
             }
            
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
       
      };
      
      const handleBack = (data) => {
        // console.log(personal)
        // setPersonal((personal)=> console.log(personal))
        // debugger
        // if(data=="personal")
        {
          // formikPersonalInfo.setValues({'first_name': personal.first_name});
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      };

      const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
          // You probably want to guard against something like this,
          // it should never occur unless someone's actively trying to break something.
          throw new Error("You can't skip a step that isn't optional.");
        }
    
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
          const newSkipped = new Set(prevSkipped.values());
          newSkipped.add(activeStep);
          return newSkipped;
        });
      };
    
      const handleReset = () => {
        setActiveStep(0);
      };
      const isStepOptional = (step: number) => {
        return step === 1;
      };
    
      const isStepSkipped = (step: number) => {
        // return skipped.has(step);
      };

      const locations = [
        { id: 'lga', value: 'Local Government Area', label: 'Local Government Area' },
        { id: 'gps', value: 'GPS Coordinates', label: 'GPS Coordinates' }]
const formikRegular =useFormik({
  initialValues: {     
      first_name: '',
      last_name:  '',
      email:  '',
      phone_mobile: '',
      gender: '',
      town: '',
      select_location:  '',
      community_project: [],
      lga:  '',
      longitude: '',
      latitude: '',
      landmark:''          
    },
    onSubmit: (values) => {
    }
})
    const formikPersonalInfo = useFormik({
        validationSchema: validationPersonalFormSchema,
        // enableReinitialize: true,
        initialValues: {       
         type:'personal', 
          first_name: '',
          last_name:  '',
          email:  '',
          phone_mobile: '',
          gender: ''        
        },
       
        onSubmit: (values) => {  
        },
      })

    const formikCommunityProject = useFormik({
    validationSchema: validationCommunityProjectSchema,
    enableReinitialize: true,
    initialValues: {  
        type:'project',
        town: '',
        town2:'',
        select_location:  '',
        community_project: [],
        lga:  '',
        longitude: '',
        latitude: '',
        landmark:'' ,
        contact_name:'',   
        phone_mobile:''    
    },
    onSubmit: (values) => {  
        // debugger; 
        // valuesG.town=values.town;
        // console.log(valuesG);        
    },
    })
    

    const formikCommunityAssociate = useFormik({
        validationSchema: validationCommunityAssociationSchema,
        enableReinitialize: true,
        initialValues: { 
        type:'community',
        ongoing_project_location: '',
            lga:  '',
            town:  '',
            town2:  '',
            ongoing_project: '',
            image: '',
            longitude:'',
            latitude:'',
            project_name:'',
            project_description:''        
        },
        onSubmit: (values) => {  
          
        },
        })
    const formikYouth = useFormik({
      validationSchema: validationJobSchema,
      enableReinitialize: true,
      initialValues: {       
          type: 'youth',
          jobs:  [],
          suggestion:'',
          other_job:''       
      },
      onSubmit: (values) => {  
        
          // debugger;        
          //   const fundData = {
          //     email: values.email,
          //     first_name: values.first_name,
          //     last_name: values.last_name,
          //     phone_mobile: values.phone_mobile,
          //     gender:values.gender
          //   }
          //   personalInformationMutation.mutate(fundData)
          //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
          // setActiveStep(1);
          //   Survey2Form.protoTypes={activeStep:3} 
          // handleNext()
      },
      })

      const handleSelectRegularchange = (e, name) => {
        formikCommunityProject.setFieldValue(name, e);
        // setCategoryId(e.id);
      }

      const { data: projects } = useQuery(['projects'], getAllProperty, {
        select: (res) => {
          const permOpt = res.data.projects.map(item => {
            return { id: item.id, value: item.id, label: item.name }
          })
    
          return permOpt
        },
        onError: err => {
          console.log(err)
        },
        staleTime: Infinity,
        cacheTime: Infinity
      })

      const { data: ongoingProject } = useQuery(['ongoing'], getOngoingProject, {
        select: (res) => {
          const permOpt = res.data.projects.map(item => {
            return { id: item.id, value: item.id, label: item.name }
          })
    
          return permOpt
        },
        onError: err => {
          console.log(err)
        },
        staleTime: Infinity,
        cacheTime: Infinity
      })
      const { data: youthJob } = useQuery(['jobs'], getYouthJob, {
        select: (res) => {
          const permOpt = res.data.youths.map(item => {
            return { id: item.id, value: item.id, label: item.name }
          })
    
          return permOpt
        },
        onError: err => {
          console.log(err)
        },
        staleTime: Infinity,
        cacheTime: Infinity
      })
    
      const { data: lgas } = useQuery(['lga'], getAllLocation, {
        select: (res) => {
          const permOpt = res.data.lgas.map(item => {
            return { id: item.id, value: item.id, label: item.name }
          })
    
          return permOpt
        },
        onError: err => {
          console.log(err)
        },
        staleTime: Infinity,
        cacheTime: Infinity
      })
    
    

        
      return {
        formikPersonalInfo, personalInformationMutation,formikCommunityAssociate,
         formikCommunityProject,formikYouth, activeStep, setActiveStep, steps,isStepOptional,
          isStepSkipped,handleReset, handleBack,handleNext,projects,lgas, handleSelectRegularchange,
          valuesG,personal,projectData,youthData,communityData,
          locations,ongoingProject,youthJob      }
}

export default ManageForms;