import Contact from "components/shared/Contact"
import Footer from "components/shared/Footer"
import Header from "components/shared/Header"


const GeneralLayout = ({ children}) => {
  return (
    <div id='page-top'>
      <Header />
      
      {children}
      {/* <Contact />*/}
      <Footer /> 
    </div>
  )
}

export default GeneralLayout
