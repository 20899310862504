const Footer = () => {

    return (
        <footer id="footer" class="transparent">
		    
			<div id="copyrights">
				<div class="container-fluid">

					<div class="row col-mb-30">

						<div class="col-md-6 text-center text-md-left">
							Copyrights © 2024 Edo State Social Impact Platform (SIP). All Rights Reserved
						</div>

						<div class="col-md-6 text-center text-md-right">
							
							{/* <i class="icon-envelope2"></i> info@sip-edo.org <span class="middot">·</span> 
							<i class="icon-headphones"></i> +234 804 654 6369  */}
						</div>

					</div>

				</div>
			</div>
		</footer>
    )
}
export default Footer;