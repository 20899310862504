import {Fragment} from 'react';
import { Field, ErrorMessage, FormikProvider  } from 'formik';

import TextError from "./TextError";


function CheckBoxGroup(props){
    const {formik,label, name,options, ...rest}= props;
    return (

        <div className="form-control">
             <FormikProvider value={formik}>
                <Field id={name} name={name} {...rest}>
                    {(({field}) =>{                        
                        return (
                            <ul className='wpforms-field-required'>
                        {options?.map(option=>{
                            return (
                                <Fragment key={option.id}>
                                    <li class="choice-1 depth-1">
                                    <input
                                    type="checkbox"
                                    // id={option.id}
                                    {...field}
                                    value={option.id}
                                    // checked={field.value.includes(option.id)}
                                    data-rule-check-limit="true"
                                    aria-errormessage="wpforms-6-field_11_1-error"
                                    />
                                    <label class="wpforms-field-label-inline">{option.label}</label>
                                    </li>
                                </Fragment>
                            )
                        }) }
                        </ul>
                        )                       
                    })}
                   
                </Field>
                {/* <Field
              name={name}
              render={(props) => {
                const { field } = props;
                var hasError;
                debugger;
                const init = Object.values(props.form.initialValues);
                if (!props.field.value) {
                  hasError = "Required";
                } else if (props.field.value === "0") {
                  hasError = "Please enter a non-zero number";
                } else if (isNaN(props.field.value)) {
                  hasError = "Please enter a non-zero number";
                }
                return (
                  <>
                    <input style={{ width: "50px" }} {...field} type="text" />
                    <span>{hasError}</span>
                  </>
                );
              }}
            /> */}

{props.error && <small className="error">{props.error} </small>}
                <ErrorMessage name={name} 
                component={TextError}
                
                
                />
            </FormikProvider>
        </div>
    )
}

export default CheckBoxGroup