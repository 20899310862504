
import { authAtom } from 'hooks/state/auth';
import { userSelector } from 'hooks/state/user';
import React, { Component, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { navAdminMenu } from './menu';
import { isArray } from 'lodash'
import Header from 'components/shared/Header';
import useAuth from 'hooks/useAuth';
import HeaderContent from 'components/shared/HeaderContent';
import Footer from 'components/shared/Footer';

const navChildren = (items) => {
    return items.map(item => item.href)
}


const Sidebar = ({ clicked, setClicked, children }) => {
    const location = useLocation();
    const { pathname } = location;
    const [navMenu, setNavMenu] = useState(null)
    const { logout } = useAuth()
    const [open, setOpen] = useState(false)
    const auth = useRecoilValue(authAtom)
    const userDetails = useRecoilValue(userSelector)
    const splitLocation = pathname.split("/");
    React.useEffect(() => {
        if (auth?.admin) {
            
            // setNavMenu(navAdminMenu)
            //console.log(navAdminMenu)
            
       
        } 
    }, [])
    return (
        <div>
            <HeaderContent clicked={clicked} setClicked={setClicked} setOpen={setOpen} user={userDetails?.first_name} />
            

           <Footer/>
        </div>
    )
}
export default Sidebar;
