import LandingPage from "pages/LandingPage";
import SigninPage from "pages/Signin";
import Dashboard from "pages/admin/Dashboard";
import CommunityProject from "pages/admin/Dashboard/communityProject";
import OngoingProject from "pages/admin/Dashboard/ongoingProject";
import YouthJob from "pages/admin/Dashboard/youthJob";
import { Routes, Route, Link } from "react-router-dom";
import ProtectedRoutes from "utility/protectedRoutes";

export const token = 'userToken'
export const ADMIN_ROUTES = "/admin";
export const HOME = "/";
export const ADMIN_SIGNIN = "/admin/signin";
export const DASHBOARD = "dashboard"; 
export const REPORT = "/admin/report"; 
export const COMMUNITY = "/admin/community"; 
export const ONGOING = "/admin/ongoing"; 
export const YOUTH = "/admin/youth"; 

const AppRoute = () => {

    return (
      <Routes>
        <Route index element={<LandingPage />} />
        <Route path={ADMIN_SIGNIN} element={<SigninPage />} />
        <Route path={ADMIN_ROUTES} element={<ProtectedRoutes authRequired={true} />} >
        <Route index element={<Dashboard />} />
        <Route path={DASHBOARD} element={<Dashboard />} />
        <Route path={COMMUNITY} element={<CommunityProject />} />
        <Route path={ONGOING} element={<OngoingProject />} />
        <Route path={YOUTH} element={<YouthJob />} />

        </Route>
      </Routes>
    )
}
export default AppRoute