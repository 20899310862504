import { IdleTimeOutModal } from "components/IsIdleModal";
import { authAtom } from "hooks/state/auth";
import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { Navigate, Outlet } from "react-router-dom";
import { useRecoilValue } from "recoil";

import AppLayout from "components/Layout";
import { HOME,ADMIN_SIGNIN } from "routes/navigator";
import useAuth from "hooks/useAuth";

const ProtectedRoutes = ({ authRequired = false, redirectPath = HOME }) => {
    const auth = useRecoilValue(authAtom);
    const { logout } = useAuth()
    const [openModal, setOpenModal] = useState(false)
    const token = auth?.token;
    const onPrompt = () => {
        setOpenModal(true)
    }

    const onIdle = () => {
        // Close Modal Prompt
        setOpenModal(false)
        // Do some idle action like log out your user
        if (token) {
            logout(ADMIN_SIGNIN)
        }
    }

    const onActive = (event) => {
        // Close Modal Prompt
        setOpenModal(false)
        // Do some active action
        reset()
    }

    const onAction = (event) => {
        // Do something when a user triggers a watched event
        reset()
    }

    const {
        reset,
        getRemainingTime,
    } = useIdleTimer({
        onPrompt,
        onIdle,
        onActive,
        onAction,
        timeout: 1000 * 60 * 25,
        promptTimeout: 5000,
        events: [
            'mousemove',
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mousewheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'MSPointerMove',
            'visibilitychange'
        ],
        eventsThrottle: 200,
        name: 'idle-timer',
    })

    if (authRequired) {
        return (
            <AppLayout >
                 {token ? <Outlet /> : <Navigate to={redirectPath} />}
                 <IdleTimeOutModal showModal={openModal} handleClose={() => setOpenModal(false)} handleLogout={() => logout(ADMIN_SIGNIN)} />
             </AppLayout >
        )
    }
    return <Outlet />;
};

export default ProtectedRoutes
