import { ErrorMessage, Field, FormikProvider } from "formik";
import TextError from "./TextError";
import { array } from "yup";

function SelectM(props){
    const{formik, label, name, options, placeholder, ...rest}=props

    var newoptions= [{id:0,value:placeholder}, ...options]
    return (
        <div className="form-control">
            <FormikProvider value={formik}>
                <label htmlFor={name}>{label}</label>
                <Field as='select' id={name} name={name} {...rest}>
                    {                        
                        newoptions.map((option)=>{
                            return(
                                <option key={option.id} value={option.id}>{option.value}</option>
                            )
                        })
                    }
                </Field>
                <ErrorMessage name={name} component={TextError}/>
            </FormikProvider>
        </div>
    )
}
export default SelectM;