const Select = ({ id, options, selected,defaultVal, handleChange, className, disabled = false, placeholder = 'Choose...' }) => {
    
    const filtered = options?.filter(c => {
      return c.value=== defaultVal;
    });
  return (
      <select
        id={id}
        name={id}
        value={JSON.stringify(selected)} 
        defaultValue={JSON.stringify(filtered? filtered[0]:'')}       
        className={className}
        disabled={disabled}
        onChange={handleChange}
      >
        <option >{placeholder}</option>
        {options?.map((option) => (
          <option key={option.name || option.label} value={JSON.stringify(option)}>
            {option.name || option.label}
          </option>
        ))}
      </select>
    );
  };
  
  export default Select;
  