// import { Select } from '@mui/material';
import LoadingIndicator from 'components/LoadingIndicator';
import { mapLgasToTown } from 'hooks/Generic';
import { Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import Select from './select';
// import Select from '../../innerComponents/Select';

const LgaSelect = ({ selected, handleChange, disabled = false, placeholder = 'Select Local Government Area', lga }) => {
    const lgas = useRecoilValue(mapLgasToTown);
    return (
        <Suspense fallback={<LoadingIndicator />}>
            {!disabled ? <Select id="country" placeholder={placeholder} className="form-select" selected={selected} handleChange={handleChange} options={lga} disabled={disabled} />
                : <input className="form-control" id="selectedLga" name='selectedLga' type="text" value={selected?.name} disabled={disabled} />}
        </Suspense>
    );
}

export default LgaSelect;
