import { Box } from "@mui/material";
import ManageForms from "./ManageForms";
import CheckBoxGroup from "components/CheckBoxGroup";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";

const YouthJob=({onClickCallback, onClickBack, currentStep})=>{
   const{ formikYouth,activeStep, setActiveStep, steps, handleNext, handleBack,youthJob } =ManageForms();
   const [capVal, setCapVal]=useState(null);
    return (
        <div class="wpforms-page wpforms-page-4 last " data-page="4" style={{display: currentStep==3?'': 'none'}}>
            <div id="wpforms-6-field_24-container" class="wpforms-field wpforms-field-divider" data-field-id="24">
               <h3 id="wpforms-6-field_24" name="wpforms[fields][24]" aria-errormessage="wpforms-6-field_24-error" aria-describedby="wpforms-6-field_24-description">Youth Jobs Creation Opportunities In Your Area</h3>
               <div id="wpforms-6-field_24-description" class="wpforms-field-description">Pick any top four job creation opportunities for Youths in your area.</div>
            </div>
            <div id="wpforms-6-field_25-container" class="wpforms-field wpforms-field-checkbox wpforms-list-2-columns wpforms-conditional-trigger" data-field-id="25">
               <fieldset>
                  <legend class="wpforms-field-label">Pick any top four job creation opportunities for youth in your area<span class="wpforms-required-label" aria-hidden="true">*</span></legend>
                  <CheckBoxGroup
                     formik={formikYouth} 
                     label="Checkbox Topics" 
                     name="jobs"
                     options={youthJob}
                     error={formikYouth.values.jobs.length ==0 ?'youth job(s) is required': ""}
                     />  
                     
                  <div id="wpforms-6-field_25-description" class="wpforms-field-description">Choose "Others" to add your own.</div>
               </fieldset>
            </div>
            <div id="wpforms-6-field_26-container" class="wpforms-field wpforms-field-text wpforms-conditional-field wpforms-conditional-hide" data-field-id="26"><label class="wpforms-field-label" for="wpforms-6-field_26">Other Job Opportunity</label>
            <input type="text" id="other_job" class="wpforms-field-large" 
               name="other_job" placeholder="Other Job Opportunity" 
               aria-errormessage="wpforms-6-field_26-error"
               value={formikYouth.values.other_job} 
               onChange={formikYouth.handleChange} 
               onBlur={formikYouth.handleBlur} 
            /></div>
            <div id="wpforms-6-field_26-container" class="wpforms-field wpforms-field-text wpforms-conditional-field wpforms-conditional-hide" data-field-id="26"><label class="wpforms-field-label" for="wpforms-6-field_26">Feedback / Suggestions</label>
            <input type="text" id="wpforms-6-field_26" class="suggestion" 
            name="suggestion" placeholder="Enter Suggestions" 
            aria-errormessage="wpforms-6-field_26-error"
            value={formikYouth.values.suggestion} 
               onChange={formikYouth.handleChange} 
               onBlur={formikYouth.handleBlur}
            />
            </div>
            
            <div id="wpforms-6-field_9-container" class="wpforms-field wpforms-field-pagebreak" data-field-id="9"></div>
            <div className="row">
               <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  onChange={(val)=> setCapVal(val)}
                  />
            </div>
            <div class="row">            
                        <button
                        class=" wpforms-page-button wpforms-page-prev"
                        color="inherit"
                        // disabled={activeStep === 0}
                        onClick={(e)=>{
                           e.preventDefault();
                           onClickBack()
                        }}
                        // onClick={onClickBack}
                        sx={{ mr: 1 }}
                        >
                        Back
                        </button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        
                        <button class= " wpforms-page-button wpforms-page-next" type="submit"
                        disabled={!capVal}
                         onClick={(e) =>{ 
                            if(formikYouth.isValid)
                            {
                               onClickCallback(formikYouth.values)
                            }
                        }}
                        >
                        {activeStep === steps.length - 2 ? 'Finish' : 'Next'}
                        </button>    
            </div>
         </div>
    )
}
export default YouthJob;