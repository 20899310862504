// import * as React from 'react';
import {
    Fragment,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
  } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PersonalInformation from './survery-forms/personal-information';
import CommunityProject from './survery-forms/community-project';
import CommunityAssociate from './survery-forms/community-associate';
import YouthJob from './survery-forms/youth-job';
import ManageForms from './survery-forms/ManageForms';
import { scrollToErrors } from 'components/Util/functions';
import { ProgressBar } from 'react-bootstrap';


export default function Survey2Form(props) {
    const{ formikPersonalInfo,personalInformationMutation,steps
      ,handleNext,PersonalInfoValues,valuesG,personal,projectData,communityData,youthData,
       activeStep, isStepOptional, isStepSkipped,handleBack, handleReset } =ManageForms();
       

  const handleNext13 = (e) => {
    // let newSkipped = skipped;
    // if (isStepSkipped(activeStep)) {
    //   newSkipped = new Set(newSkipped.values());
    //   newSkipped.delete(activeStep);
    // }
    //personalInformationMutation.mutate()
    // scrollToErrors(formikPersonalInfo.errors);
    //setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // setSkipped(newSkipped);
  };


  

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption"></Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        
        <Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            {/* <Button onClick={handleReset}>Reset</Button> */}
          </Box>
        </Fragment>
      ) : (
        <Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>Page {activeStep + 1} - Step {activeStep + 1} of 4
          
          </Typography>
          {/* abel={`60%`} */}
          <ProgressBar now={25*(activeStep+1)} />
          
            <Box sx={{  flexDirection: 'row', pt: 2 }}>               
                    <div class="wpforms-field-container">
                        

                        <PersonalInformation onClickCallback={handleNext} values={personal} currentStep={activeStep}/>
                        <CommunityProject onClickCallback={handleNext} values={projectData} onClickBack={handleBack} currentStep={activeStep}/>
                        <CommunityAssociate onClickCallback={handleNext} values={communityData} onClickBack={handleBack} currentStep={activeStep}/>
                        <YouthJob onClickCallback={handleNext} values={youthData}onClickBack={handleBack} currentStep={activeStep}/>

     
                        
                      {/* {activeStep===0 ?
                        (<PersonalInformation onClickCallback={handleNext} values={personal}/>):''
                      }
                      {activeStep===1?
                        (<CommunityProject onClickCallback={handleNext} values={projectData} onClickBack={handleBack}/>):''
                      }
                      {activeStep===2?
                        (<CommunityAssociate onClickCallback={handleNext} values={communityData} onClickBack={handleBack}/>):''
                      }
                        {activeStep===3?
                        (<YouthJob onClickCallback={handleNext} values={youthData}onClickBack={handleBack}/>):''
                            
                        } */}



                        {/* {(activeStep+1)===1 ?
                        (<PersonalInformation onClickCallback={handleNext} values={personal}/>):
                        (activeStep+1)===2?
                        (<CommunityProject onClickCallback={handleNext} values={projectData} onClickBack={handleBack}/>):
                        (activeStep+1)===3?
                        (<CommunityAssociate onClickCallback={handleNext} values={communityData} onClickBack={handleBack}/>):
                        (activeStep+1)===4?
                        (<YouthJob onClickCallback={handleNext} values={youthData}onClickBack={handleBack}/>):''
                            
                        } */}
                        {console.log(activeStep)}
                    {/* {console.log(userDetails)} */}
                    </div>
                    {/* <div class="row">
                        <Button
                        class="wpforms-page-button wpforms-page-prev"
                        color="inherit"
                        disabled={props.activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        >
                        Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        
                        <Button class="wpforms-page-button wpforms-page-next" onClick={handleNext}>
                        {props.activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>    
                    </div> */}

            </Box>
          
        </Fragment>
      )}
    </Box>
    // <createMultiForm activeStep={activeStep}/>
  );
}

function createMultiForm(props){
    const {isStepOptional, isStepSkipped,handleBack,handleNext,handleReset,steps,activeStep} = ManageForms();
    return (
        <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        
        <Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </Fragment>
      ) : (
        <Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>Page {activeStep + 1} Step {activeStep + 1} of 4</Typography>
          
            <Box sx={{  flexDirection: 'row', pt: 2 }}>               
                    <div class="wpforms-field-container">
                        
                        {(props.activeStep+1)===1 ?
                        (<PersonalInformation/>):
                        (props.activeStep+1)===2?
                        (<CommunityProject/>):
                        (props.activeStep+1)===3?
                        (<CommunityAssociate/>):
                        (props.activeStep+1)===4?
                        (<YouthJob/>):''
                            
                        }
                    
                    </div>
                    <div class="row">
                        <Button
                        class="wpforms-page-button wpforms-page-prev"
                        color="inherit"
                        disabled={props.activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        >
                        Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        
                        <Button class="wpforms-page-button wpforms-page-next" onClick={handleNext}>
                        {props.activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>    
                    </div>

            </Box>
          
        </Fragment>
      )}
    </Box>
    )
}