import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./utility/scrollToTop";
import AppRoute from "./routes/navigator";
import { ReactQueryDevtools } from 'react-query/devtools'
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { RecoilRoot } from "recoil";
import { Toaster } from 'react-hot-toast';
import LoadingIndicator from "components/LoadingIndicator";
import 'react-phone-input-2/lib/style.css';

function App() {
  const [queryClient] = React.useState(() => new QueryClient())
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <BrowserRouter>
            <ScrollToTop />
            <Toaster />
            <Suspense fallback={<LoadingIndicator />}>
              <AppRoute />
            </Suspense>
          </BrowserRouter>
          <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
        </RecoilRoot>
      </QueryClientProvider>
    </>
  )
 }

export default App;
