import moment from 'moment';
import {
    uniqueNamesGenerator,
    names, adjectives, colors, NumberDictionary
} from "unique-names-generator";
const config = {
    dictionaries: [names, names],
    separator: " ",
    length: 2,
    style: "capital"
};
const statusChance = Math.random()
const numberDictionary = NumberDictionary.generate({ min: 9178234563, max: 9199999999 });
const minDate = new Date(2020, 0, 1)
const maxDate = Date.now();
const timestamp = new Date(+minDate + Math.floor(Math.random() * (maxDate - minDate)));
export const range = len => {
    const arr = []
    for (let i = 0; i < len; i++) {
        arr.push(i)
    }
    return arr
}

export const newAdvert = () => {
    const minDate = new Date(2020, 0, 1)
    const maxDate = Date.now();
    const timestamp = new Date(+minDate + Math.floor(Math.random() * (maxDate - minDate)));

    return {
        poster: uniqueNamesGenerator(config),
        date: moment().format(timestamp, 'yyyy/MM/dd'),
        donation: uniqueNamesGenerator({ dictionaries: [NumberDictionary.generate({ min: 2000, max: 10000 })] }),
    }
}
export const callHistory = () => {
    const firstName = uniqueNamesGenerator({ dictionaries: [names] })
    const lastName = uniqueNamesGenerator({ dictionaries: [names] })
    const hrs = Math.round(Math.random() * 11) + 1;
    const mins = Math.round(Math.random() * 59);
    const contact = uniqueNamesGenerator({ dictionaries: [numberDictionary] })
    const addDictionary = NumberDictionary.generate({ min: 1, max: 10 });
    const center = uniqueNamesGenerator({ dictionaries: [addDictionary] })

    return {
        donation: uniqueNamesGenerator({ dictionaries: [NumberDictionary.generate({ min: 2000, max: 10000 })] }),
        name: `${firstName} ${lastName}`,
        contactno: `+${contact}`,
        email: `${firstName}@${lastName}.com`,
        
    }
}
export const aaidUser = () => {
    const timestamp = new Date(+minDate + Math.floor(Math.random() * (maxDate - minDate)));
    const firstName = uniqueNamesGenerator({ dictionaries: [names] })
    const lastName = uniqueNamesGenerator({ dictionaries: [names] })
    const contact = uniqueNamesGenerator({ dictionaries: [numberDictionary] })

    return {
        name: `${firstName} ${lastName}`,
        email: `${firstName}@${lastName}.com`,
        contactno: `+${contact}`,
        
        date: moment().format(timestamp, 'yyyy/MM/dd'),
    }
}
