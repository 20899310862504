import React, { useEffect, useMemo, useState } from "react";
// import useAdminActions from "hook/useAdminActions";
import { useQuery } from "react-query";
import Table from "components/shared/TableTwo";
import makeData from "components/Util/makeData";
import useAdminActions from "hooks/UseAdminActions";
import EnhancedTable from "components/shared/EnhancedTable";
import { toStartCase } from "utility/functions";
import { format } from "date-fns";
import GlobalEnhancedTable from "components/shared/GlobalEnhancedTable";

const Dashboard = () => {
    const {dashboard, dashboardDownload } =useAdminActions(); 
    const [search, setSearch] = React.useState('');
    const [data, setData] = React.useState(React.useMemo(() => makeData('call history', 30), []))
    const [page, setPage] = React.useState(1)
    const [skipPageReset, setSkipPageReset] = React.useState(false)
    const [donations, setDonations] = React.useState([]) 
    const allDonations = useQuery(['donations', page,search], () => dashboard(page,search), {

        onError: err => {
          console.log(err)
          // notify('post creation failed!', { type: 'error' })
        },
        staleTime: Infinity,
        cacheTime: Infinity
      })
    const donationColumn = useMemo(
        () => [
    
          {
            Header: 'Full Name',
            id: 'creator',
            accessor: (row) => toStartCase(`${row?.first_name} ${row?.last_name}`),
          },
          {
            Header: 'Email',
            id: 'level',
            accessor: (row) => row?.email,
          },
          {
            Header: 'Phone Number',
            id: 'tagline',
            accessor: (row) => row?.phone,
          },
          {
            Header: 'Gender',
            id: 'gender',
            accessor: (row) => row?.gender,
          },
          {
            Header: 'Suggestion',
            id: 'suggestion',
            accessor: (row) => row?.suggestion,
          },
          {
            Header: 'Date',
            id: 'created_at',
            accessor: (row) => format(new Date(row.created_at), 'dd/MM/yyyy'),
          },
          
        ],
        []
      )

 
    const columnCalls = React.useMemo(
        () => [ 
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Email',
                accessor: 'duration',
            },
            {
                Header: 'Phone Number',
                accessor: 'date',
            },
            {
                Header: 'Gender',
                accessor: 'time'
            }
        ],
        []
    )
    // console.log(allDonations?.data?.data?.users?.data)
    return (
        <>
            <div className="container-fluid px-4">
                <h1 className="mt-4">Dashboard</h1>
               
                
                
                <div className="card mb-4">
                    <div className="card-header">
                        <i className="fas fa-table me-1"></i>
                        Survey
                    </div>
                    <div className="card-body">
                    <div className="row">
                      <div className="col-3">
                        <input type='text' placeholder='Global Search Record' className="form-control" 
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        />
                      </div>
                      <div className="col-3">
            <button class= "btn btn-success" type="submit"
                         onClick={(e) =>{    
                           e.preventDefault()
                           dashboardDownload(page,search)
                           .then((response) => {
                            const url = window.URL.createObjectURL(new Blob([response]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `personalInfo.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                        })
                        }}
                        >
                        Download
                        </button> 
              
            </div>

                    </div>
        <GlobalEnhancedTable
              columns={donationColumn}
              data={allDonations?.data?.data?.users?.data || []}
            // data={[]}
              setData={setDonations}
              skipPageReset={skipPageReset}
              lastPage={allDonations?.data?.data?.users?.last_page}
              currentPage={page}
              setCurrentPage={setPage}
              isFetching={allDonations?.isFetching || allDonations?.isLoading}
            />            
                    

                    </div>
                </div>
                
            </div>
        </>
    );
}

export default Dashboard;
