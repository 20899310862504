
import GeneralLayout from "./master/generalLayout"
import SurveyForm from "./survey-form"
import Survey2Form from "./survey2-form"
import Survey3Form from "./survey3-form"



const LandingPage = () => {
  return (
    <GeneralLayout>
        <section id="content">
			<div id="survey" class="content-wrap">
				<div class="container">
					<div class="row align-items-top">

						<div class="col-lg-4">
						    <div class="sidebar-wrap">
    							<div class="heading-block">
    								<div class="spacer-80"></div>
    								<h1>Targeted Development </h1>
    								<div class="spacer-60"></div>
        							<p>Start the process by providing your personal details then proceed by answering the questions that follow.</p>
    							</div>
    						</div>
						</div>
						<div class="col-lg-8">
							<div class="content-block">
								<div class="spacer-70"></div>
								<div class="survey-wrap">
                                    <div class="wpforms-container wpforms-container-full wpforms-render-modern"	id="wpforms-6">						
                                        {/* <SurveyForm/> */}
                                        {/* <Stepper/> */}
                                        
                                        {/* <Survey3Form activeStep={0}/> */}
                                        <Survey2Form activeStep={0}/>
                                    </div>
                                </div>
							</div>							
						</div>						
					</div>
				</div>
				
			</div>
			<div class="spacer-80"></div>
		</section>
    </GeneralLayout>
  )
}

export default LandingPage
