import useAxios from "./useAxios";
const GenericCalls = () => {
  const axios = useAxios();

  return {
    personalInfo,
    getAllProperty,
    getAllLocation,
    getOngoingProject,
    getYouthJob,
    verifyEmail,
    verifyPhone
  }
  function personalInfo(payload) {
    const config = {headers: {'Content-Type': 'multipart/form-data'}}
    return axios.post(`/personal_information`, payload,config);
  }

  function getAllProperty() {
    return axios.get(`/projects`)
  }

  function verifyEmail(payload) {
    return axios.post(`/confirm_email`, payload);
  }
  function verifyPhone(payload) {
    return axios.post(`/confirm_phone`, payload);
  }
  function getAllLocation() {
    return axios.get(`/lgas`)
  }
  function getOngoingProject() {
    return axios.get(`/ongoingproject`)
  }
  function getYouthJob() {
    return axios.get(`/youth`)
  }
}
export default GenericCalls;