import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Header from "./shared/Header";
import Footer from "./shared/Footer";
import HeaderContent from "./shared/HeaderContent";

const AuthLayout = ({ children }) => {

    const variants = {
        hidden: { opacity: 0, x: -200, y: 0 },
        enter: { opacity: 1, x: 0, y: 0 },
        exit: { opacity: 0, x: 0, y: -100 },
    }
    return (
        <div id='page-top'>
            <HeaderContent />
            <section id="content">
			<div id="survey" class="content-wrap">
				<div class="container">
					<div class="row align-items-top">

						<div class="col-lg-4">
						    
						</div>
						<div class="col-lg-8">
							<div class="content-block">
								
								
                                {children}
							</div>							
						</div>						
					</div>
				</div>
				
			</div>
			<div class="spacer-80"></div>
		</section>
            
            
            <Footer /> 
        </div>
    );
}

export default AuthLayout;
