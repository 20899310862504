import React, { Suspense, useEffect, useMemo, useState } from "react";
// import useAdminActions from "hook/useAdminActions";
import { useQuery } from "react-query";
import Table from "components/shared/TableTwo";
import makeData from "components/Util/makeData";
import useAdminActions from "hooks/UseAdminActions";
import EnhancedTable from "components/shared/EnhancedTable";
import { toStartCase } from "utility/functions";
import { format } from "date-fns";
import SelectBox from "components/SelectBox";
import ManageForms from "pages/survery-forms/ManageForms";
import GlobalEnhancedTable from "components/shared/GlobalEnhancedTable";
import Select from "components/select";
import LoadingIndicator from "components/LoadingIndicator";
import LgaSelect from "components/LgaSelect";
import TownSelect from "components/TownSelect";

const OngoingProject = () => {
    const {getAllOngoingProjects,getAllOngoingProjectDownloads } =useAdminActions(); 
    const {projects,lgas } =ManageForms(); 
    const [data, setData] = React.useState(React.useMemo(() => makeData('call history', 30), []))
    const [page, setPage] = React.useState(1)
    const [lga, setlga] = React.useState('');
    const [lga2, setlga2] = React.useState('');
    const [town, setTown] = React.useState('');
    const [town2, setTown2] = React.useState('');
    const [search, setSearch] = React.useState('');
    const [searchProject, setSearchProject] = React.useState('');
    const [skipPageReset, setSkipPageReset] = React.useState(false);
    const [ongoingProj, setOngoingProj] = useState([]);
    const [donations, setDonations] = React.useState([])

    const allOngoingProjects = useQuery(['ongoingprojects', page,search,lga, town,searchProject], () => getAllOngoingProjects(page,search,lga,town,searchProject), {
      onSuccess: (res) => {         
        setOngoingProj(res.data.projects.data)
        // const permOpt = res.data.projects.data.map(item => {
        //   return { id: item.id, value: item.id, email: item.email, phone: item.phone_mobile, label: toFullName({ firstName: item.first_name, lastName: item.last_name }) }
        // })
        
        // setCommunityOpts(permOpt)
      },
      onError: err => {
        console.log(err)
        // notify('post creation failed!', { type: 'error' })
      },
      staleTime: Infinity,
      cacheTime: Infinity
    })
 
   const [selectedTown, setSelectedTown] = useState()

    const donationColumn = useMemo(
        () => [
    
          {
            Header: 'Full Name',
            id: 'creator',
            accessor: (row) => toStartCase(`${row?.personal_information?.first_name} ${row?.personal_information?.last_name}`),
          },
          {
            Header: 'Email',
            id: 'level',
            accessor: (row) => row?.personal_information?.email,
          },
          // {
          //   Header: 'Phone Number',
          //   id: 'tagline',
          //   accessor: (row) => row?.personal_information?.phone,
          // },
          // {
          //   Header: 'Gender',
          //   id: 'gender',
          //   accessor: (row) => row?.personal_information?.gender,
          // },

          {
            Header: 'Ongoing Project',
            id: 'project',
            accessor: (row) => row?.project?.name,
          },
         
          {
            Header: 'Landmark',
            id: 'landmark',
            accessor: (row) => row?.landmark,
          },
          {
            Header: 'Contact Name',
            id: 'contactName',
            accessor: (row) => row?.contact_name,
          },
          {
            Header: 'Contact Mobile',
            id: 'contactMobile',
            accessor: (row) => row?.contact_mobile,
          },
          {
            Header: 'LGA',
            id: 'lga',
            accessor: (row) =>row.local_government_id!=null? row?.lga.name: row.longitude,
          },
          {
            Header: 'City',
            id: 'city',
            accessor: (row) =>row.city===null? row.latitude: row.city.name,
          },
          {
            Header: 'Date',
            id: 'created_at',
            accessor: (row) => format(new Date(row.created_at), 'dd/MM/yyyy'),
          },
          
        ],
        []
      )

 
    return (
        <>
            <div className="container-fluid px-4">
                <h1 className="mt-4">Ongoing Project</h1>
               
                
                
                <div className="card mb-4">
                    <div className="card-header">
                        <i className="fas fa-table me-1"></i>
                        Projects
                    </div>
                    <div className="card-body">
         <div className="row">
            <div className="col-3">
              <input type='text' placeholder='Global Search Record' className="form-control" 
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              />
            </div>
            <div className="col-3" style={{width:'20%'}}>
            <Select
                        placeholder="Select Ongoing Project"
                    id="project"
                    name="project"
                    className="wpforms-field-large wpforms-field-required choicesjs-select choices__input choices__input--hidden"
                    // selected={JSON.parse(selectedStatus)}
                    handleChange={(e) => {                      
                        setSearchProject(JSON.parse(e.target.value)?.value);
                    }}
                    options={projects}
                /> 
            
            </div>
            <div className="col-3" style={{width:'20%'}}>
            <Suspense fallback={<LoadingIndicator />} >
                                             <LgaSelect selected={lga2} lga={lgas} handleChange={(e) => {
                                             
                                             setlga(JSON.parse(e.target.value).value)  
                                             setlga2(JSON.parse(e.target.value))  
                                             }}  />
                                          </Suspense>
            
            </div>
            <div className="col-3" style={{width:'20%'}}>
              
            <Suspense fallback={<LoadingIndicator />} >
              {lga===null?'':
                (<TownSelect selectedLga={lga2} selected={town2} handleChange={(e) => {
                setTown(JSON.parse(e.target.value).id) 
                setTown2(JSON.parse(e.target.value)) 
                }}  />)}
            </Suspense>

            </div>
            <div className="col-3">
            <button class= "btn btn-success" type="submit"
                         onClick={(e) =>{    
                           e.preventDefault()
                           getAllOngoingProjectDownloads(page,search,lga, town,searchProject)
                           .then((response) => {
                            const url = window.URL.createObjectURL(new Blob([response]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `ongoingProject.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                        })
                        }}
                        >
                        Download
                        </button> 
              
            </div>
            </div>
            
                    
<GlobalEnhancedTable
              columns={donationColumn}
              data={allOngoingProjects?.data?.data?.projects?.data || []}
            // data={[]}
              setData={setOngoingProj}
              skipPageReset={skipPageReset}
              lastPage={allOngoingProjects?.data?.data?.projects?.last_page}
              currentPage={page}
              setCurrentPage={setPage}
              isFetching={allOngoingProjects?.isFetching || allOngoingProjects?.isLoading}
            />
            {/* <MembershipEnhancedTable
              columns={MembershipColumns}
              data={allDonors?.data?.data?.users?.data || []}
              setData={setDonors}
              skipPageReset={skipPageReset}
              lastPage={allDonors?.data?.data?.users?.last_page}
              currentPage={page}
              setCurrentPage={setPage}
              isFetching={allDonors?.isFetching || allDonors?.isLoading}
            /> */}
                    </div>
                </div>
                
            </div>
        </>
    );
}

export default OngoingProject;
