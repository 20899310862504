

import { REPORT } from "routes/navigator";



export const navAdminMenu = [
    { name: 'Dashboard', hasChild: false, href: '/', id: 'dashboard', icon: 'fas fa-tachometer-alt' },
    
    // {
    //     name: 'Report', hasChild: true, href: '#', id: 'donation', icon: 'fas fa-money-bill-1',
    //     children: [{ name: 'Donate', hasChild: false, href: REPORT, icon: 'fas fa-money-bill-1' },
    //     { name: 'Donation History', hasChild: false, href: REPORT, icon: 'fas fa-clock-rotate-left' }]
    // },
    
    
]
